import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

//komponen
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//gambar
import HomeIcon from '@mui/icons-material/Home';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import NoFoodIcon from '@mui/icons-material/NoFood';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// form
import Beranda from './beranda';
import Barista from './barista';
import StokHabis from './stok_habis';
import Pengaturan from './pengaturan';

function Utama(props) {
    //state
    const [pilih_menu, setPilihMenu] = useState("beranda");
    const [tampil_dialog_keluar, setTampilDialogKeluar] = useState(false);

    if (!localStorage.getItem("login")) {
        return <Navigate to="/login" />;
    }
    else {
        return (
            <div>
                <Dialog
                    open={tampil_dialog_keluar}
                    onClose={() => setTampilDialogKeluar(false)}
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Apakah anda yakin ingin keluar dari aplikasi?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setTampilDialogKeluar(false)} autoFocus>Tidak</Button>
                        <Button
                            onClick={() => {
                                setTampilDialogKeluar(false);
                                props.proses_keluar();
                            }}
                        >Ya</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{ backgroundColor: "#eff0f0", height: "100vh" }}>
                    <Box sx={{ pb: 7 }}>
                        <Box
                            sx={pilih_menu === "beranda" ? { width: "100%" } : { display: "none" }}
                        >
                            <Beranda aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "barista" ? { width: "100%" } : { display: "none" }}
                        >
                            <Barista aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "stok habis" ? { width: "100%" } : { display: "none" }}
                        >
                            <StokHabis aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "pengaturan" ? { width: "100%" } : { display: "none" }}
                        >
                            <Pengaturan aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                            <BottomNavigation
                                showLabels
                                sx={{
                                    backgroundColor: "#fe9400",
                                    overflowX: "auto"
                                }}
                                value={pilih_menu}
                                onChange={(event, newValue) => {
                                    if (newValue === "keluar") {
                                        setTampilDialogKeluar(true);
                                        return;
                                    }
                                    setPilihMenu(newValue);
                                }}
                            >
                                <BottomNavigationAction
                                    value="beranda"
                                    label="Beranda"
                                    icon={<HomeIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="barista"
                                    label="Barista"
                                    icon={<LocalBarIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="stok habis"
                                    label="Stok Habis"
                                    icon={<NoFoodIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="pengaturan"
                                    label="Pengaturan"
                                    icon={<SettingsIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="keluar"
                                    label="Keluar"
                                    icon={<ExitToAppIcon />}
                                    sx={{ color: "white" }}
                                />
                            </BottomNavigation>
                        </Paper>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default Utama;
import React, { forwardRef } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

//gambar
import PrintIcon from '@mui/icons-material/Print';

function Lap_Pengguna(props, ref) {
    //fungsi
    const cetak = () => {            
        window.open(props.aplikasi + '/laporan/cetak_karyawan.php');
    }

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PrintIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                onClick={() => cetak()}
                            >
                                <Typography variant="body2" noWrap={true}>Cetak</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Lap_Pengguna);
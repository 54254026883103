import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

function Otorisasi(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_pembatalan, setTampilDialogPembatalan] = useState(false);
    const [tampil_dialog_pembatalan_pesanan, setTampilDialogPembatalanPesanan] = useState(false);
    const [tampil_dialog_pembebasan_hutang, setTampilDialogPembebasanHutang] = useState(false);
    const [tampil_dialog_hapus_belanja, setTampilDialogHapusBelanja] = useState(false);
    const [data_metode, setDataMetode] = useState([]);
    const [data_pembatalan, setDataPembatalan] = useState([]);
    const [data_pembatalan_pesanan, setDataPembatalanPesanan] = useState([]);
    const [data_pembebasan_hutang, setDataPembebasanHutang] = useState([]);
    const [id_permintaan, setIdPermintaan] = useState("");
    const [kasir, setKasir] = useState("");
    const [pelayan, setPelayan] = useState("");
    const [pemesan, setPemesan] = useState("");
    const [grand_total, setGrandTotal] = useState("0");
    const [waktu_transaksi, setWaktuTransaksi] = useState("");
    const [metode_sekarang, setMetodeSekarang] = useState("");
    const [metode_baru, setMetodeBaru] = useState("");
    const [id_pembayaran, setIdPembayaran] = useState("");
    const [hutang_awal, setHutangAwal] = useState(0);
    const [angsuran, setAngsuran] = useState(0);
    const [jumlah_pembebasan, setJumlahPembebasan] = useState(0);
    const [id_belanja, setIdBelanja] = useState("");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetode(data.data);
                }
                else {
                    setDataMetode([]);
                }
            })
            .catch(error => {
                setDataMetode([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_pembatalan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembatalan(data.data);
                }
                else {
                    setDataPembatalan([]);
                }
            })
            .catch(error => {
                setDataPembatalan([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_pembatalan_pesanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembatalanPesanan(data.data);
                }
                else {
                    setDataPembatalanPesanan([]);
                }
            })
            .catch(error => {
                setDataPembatalanPesanan([]);
            }
            );

        fetch(props.aplikasi + '/kasir/tampil_piutang.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembebasanHutang(data.data);
                }
                else {
                    setDataPembebasanHutang([]);
                }
            })
            .catch(error => {
                setDataPembebasanHutang([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_metode = () => {
        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetode(data.data);
                }
                else {
                    setDataMetode([]);
                }
            })
            .catch(error => {
                setDataMetode([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_pembatalan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembatalan(data.data);
                }
                else {
                    setDataPembatalan([]);
                }
            })
            .catch(error => {
                setDataPembatalan([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_pembatalan_pesanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembatalanPesanan(data.data);
                }
                else {
                    setDataPembatalanPesanan([]);
                }
            })
            .catch(error => {
                setDataPembatalanPesanan([]);
            }
            );

        fetch(props.aplikasi + '/kasir/tampil_piutang.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembebasanHutang(data.data);
                }
                else {
                    setDataPembebasanHutang([]);
                }
            })
            .catch(error => {
                setDataPembebasanHutang([]);
            }
            );
    }

    const simpan = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_ubah.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembatalan = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_pembatalan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogPembatalan(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembatalan_pesanan = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_pembatalan_pesanan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogPembatalanPesanan(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembebasan = () => {
        setProses(true);
        fetch(props.aplikasi + '/kasir/simpan_pembebasan_hutang.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembayaran: id_pembayaran,
                        jumlah_pembebasan: jumlah_pembebasan,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogPembebasanHutang(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const hapus_belanja = () => {
        setProses(true);
        fetch(props.aplikasi + '/bahan_baku/hapus_belanja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_belanja: id_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setIdBelanja("");
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialogHapusBelanja(false);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid
                        item xs={12}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    padding: "5px",
                                    marginBottom: "5px",
                                    backgroundColor: "#baa8e6",
                                    color: "white"
                                }}
                            >
                                Ubah Metode Bayar
                            </Typography>
                        </Grid>

                        <Grid
                            container spacing={1}
                        >
                            {data_metode.map((data_metode) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_metode.id_permintaan}
                                        onClick={() => {
                                            setIdPermintaan(data_metode.id_permintaan);
                                            setKasir(data_metode.kasir);
                                            setGrandTotal(data_metode.grand_total);
                                            setWaktuTransaksi(data_metode.waktu_pembayaran);
                                            setMetodeSekarang(data_metode.nama_metode_sekarang);
                                            setMetodeBaru(data_metode.nama_metode_baru);
                                            setTampilDialog(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" noWrap>{data_metode.kasir + " (Rp. " + data_metode.grand_total + ")"}</Typography>
                                                        }
                                                        secondary={
                                                            <div>
                                                                <div>
                                                                    <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>{data_metode.nama_metode_sekarang}</Typography>
                                                                    <SyncAltIcon style={{ verticalAlign: "middle" }} />
                                                                    <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>{data_metode.nama_metode_baru}</Typography>
                                                                </div>
                                                                <Typography variant="subtitle2" noWrap>{data_metode.waktu_pembayaran}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    padding: "5px",
                                    marginBottom: "5px",
                                    backgroundColor: "#baa8e6",
                                    color: "white"
                                }}
                            >
                                Batal Transaksi
                            </Typography>
                        </Grid>

                        <Grid
                            container spacing={1}
                        >
                            {data_pembatalan.map((data_pembatalan) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_pembatalan.id_permintaan}
                                        onClick={() => {
                                            setIdPermintaan(data_pembatalan.id_permintaan);
                                            setKasir(data_pembatalan.kasir);
                                            setPemesan(data_pembatalan.pemesan);
                                            setGrandTotal(data_pembatalan.grand_total);
                                            setWaktuTransaksi(data_pembatalan.waktu_pembayaran);
                                            setTampilDialogPembatalan(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" noWrap>{data_pembatalan.kasir + " (Rp. " + data_pembatalan.grand_total + ")"}</Typography>
                                                        }
                                                        secondary={
                                                            <div>
                                                                <Typography variant="subtitle2" noWrap>{data_pembatalan.pemesan}</Typography>
                                                                <Typography variant="subtitle2" noWrap>{data_pembatalan.waktu_pembayaran}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    padding: "5px",
                                    marginBottom: "5px",
                                    backgroundColor: "#baa8e6",
                                    color: "white"
                                }}
                            >
                                Batal Pesanan
                            </Typography>
                        </Grid>

                        <Grid
                            container spacing={1}
                        >
                            {data_pembatalan_pesanan.map((data_pembatalan) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_pembatalan.id_permintaan}
                                        onClick={() => {
                                            setIdPermintaan(data_pembatalan.id_permintaan);
                                            setPelayan(data_pembatalan.pelayan);
                                            setPemesan(data_pembatalan.pemesan);
                                            setGrandTotal(data_pembatalan.grand_total);
                                            setWaktuTransaksi(data_pembatalan.waktu_pesan);
                                            setTampilDialogPembatalanPesanan(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body1" noWrap>{data_pembatalan.pelayan + " (Rp. " + data_pembatalan.grand_total + ")"}</Typography>
                                                        }
                                                        secondary={
                                                            <div>
                                                                <Typography variant="subtitle2" noWrap>{data_pembatalan.pemesan}</Typography>
                                                                <Typography variant="subtitle2" noWrap>{data_pembatalan.waktu_pesan}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    padding: "5px",
                                    marginBottom: "5px",
                                    backgroundColor: "#baa8e6",
                                    color: "white"
                                }}
                            >
                                Pembebasan Hutang
                            </Typography>
                        </Grid>

                        <Grid container spacing={1}>
                            {data_pembebasan_hutang.map((data_pembebasan_hutang) => {
                                return (
                                    <Grid
                                        item xs={12} sm={6} md={4} lg={3}
                                        key={data_pembebasan_hutang.id_pembayaran}
                                        onClick={() => {
                                            setIdPembayaran(data_pembebasan_hutang.id_pembayaran);
                                            setPemesan(data_pembebasan_hutang.nama_booking);
                                            setHutangAwal(parseFloat(data_pembebasan_hutang.hutang_awal));
                                            setAngsuran(parseFloat(data_pembebasan_hutang.angsuran));
                                            setJumlahPembebasan(parseFloat(data_pembebasan_hutang.hutang_awal) - parseFloat(data_pembebasan_hutang.angsuran));
                                            setWaktuTransaksi(data_pembebasan_hutang.waktu_pesan);
                                            setTampilDialogPembebasanHutang(true);
                                        }}
                                    >
                                        <Card>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={<Typography noWrap variant="subtitle1">{data_pembebasan_hutang.nama_booking}</Typography>}
                                                        secondary={<Typography noWrap variant="body2">Rp. {format_rupiah(parseFloat(data_pembebasan_hutang.hutang_awal) - parseFloat(data_pembebasan_hutang.angsuran))}</Typography>}
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Grid
                        item xs={12}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    padding: "5px",
                                    marginBottom: "5px",
                                    backgroundColor: "#baa8e6",
                                    color: "white"
                                }}
                            >
                                Batal Belanja Bahan
                            </Typography>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid
                                item xs={12}
                            >
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container item>
                                        <Grid item xs={4} sm={4} md={3}>
                                            <Typography variant="body2">
                                                ID Belanja
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4} sm={4} md={3}>
                                            <TextField
                                                id="id_belanja"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={id_belanja}
                                                onChange={(e) => setIdBelanja(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={4} sm={4} md={3}>
                                            <LoadingButton
                                                loadingPosition="start"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteIcon />}
                                                sx={{ marginLeft: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                                loading={proses}
                                                onClick={() => setTampilDialogHapusBelanja(true)}
                                            >
                                                Hapus
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Otorisasi Permintaan Ubah Metode Bayar</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Kasir
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Metode Sekarang
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {metode_sekarang}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Metode Baru
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {metode_baru}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(true);
                        }}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Permintaan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            simpan("HAPUS");
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                        }}
                    >Ya</LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembatalan}
                onClose={() => setTampilDialogPembatalan(false)}
            >
                <DialogTitle>Otorisasi Permintaan Pembatalan Transaksi</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Kasir
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Pemesan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {pemesan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan_pembatalan("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan_pembatalan("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogPembatalan(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembatalan_pesanan}
                onClose={() => setTampilDialogPembatalanPesanan(false)}
            >
                <DialogTitle>Otorisasi Permintaan Pembatalan Pesanan</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Pelayan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {pelayan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Pemesan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {pemesan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Pesan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan_pembatalan_pesanan("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan_pembatalan_pesanan("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogPembatalanPesanan(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembebasan_hutang}
                onClose={() => setTampilDialogPembebasanHutang(false)}
            >
                <DialogTitle>Otorisasi Pembebasan Hutang</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Pemesan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {pemesan}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Hutang Awal
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(hutang_awal)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Sudah Diangsur
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(angsuran)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Sisa Hutang
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(jumlah_pembebasan)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan_pembebasan()}
                    >
                        <Typography variant="body2" noWrap={true}>Bebaskan</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogPembebasanHutang(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus_belanja}
                onClose={() => setTampilDialogHapusBelanja(false)}
            >
                <DialogTitle>Otorisasi Pembatalan Belanja Bahan Baku</DialogTitle>
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => hapus_belanja()}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogHapusBelanja(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Otorisasi);
import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

function Meja(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_meja, setDataMeja] = useState([]);
    const [no_meja, setNoMeja] = useState("");
    const [kapasitas, setKapasitas] = useState(0);
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [status_simpan, setStatusSimpan] = useState("Simpan");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/meja/tampil_meja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMeja(data.data);
                }
                else {
                    setDataMeja([]);
                }
            })
            .catch(error => {
                setDataMeja([]);
            }
            );
    }, [props.aplikasi]);

    const switch_warna_status = (param) => {
        switch (param) {
            case "1":
                return "#37ce9b";
            case "0":
                return "red";
            default:
                break;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_meja = () => {
        fetch(props.aplikasi + '/meja/tampil_meja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMeja(data.data);
                }
                else {
                    setDataMeja([]);
                }
            })
            .catch(error => {
                setDataMeja([]);
            }
            );
    }

    const bersih_input_meja = () => {
        setNoMeja("");
        setKapasitas("");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const ubah_kapasitas = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setKapasitas(event.target.value);
    }

    const perbaiki_kapasitas = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setKapasitas(event.target.value);
        }
        else {
            setKapasitas(0);
        }
    }

    const simpan = () => {
        if (no_meja.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nomor meja wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_meja").focus();
            return;
        }

        if (parseInt(kapasitas) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Kapasitas meja wajib diisi");
            setTampilNotif(true);
            document.getElementById("kapasitas").focus();
            return;
        }

        setProses(true);
        if (status_simpan === "Simpan") {
            fetch(props.aplikasi + '/meja/simpan_meja.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            no_meja: no_meja,
                            kapasitas: kapasitas,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_meja();
                        tampil_meja();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("kapasitas").focus();
                        setProses(false);
                    }
                    else {
                        tampil_meja();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("kapasitas").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_meja();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("kapasitas").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/meja/simpan_meja.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            no_meja: no_meja,
                            kapasitas: kapasitas,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_meja();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("kapasitas").focus();
                        setProses(false);
                    }
                    else {
                        tampil_meja();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("kapasitas").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_meja();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("kapasitas").focus();
                    setProses(false);
                }
                );
        }
    }

    const hapus = () => {   
        setProses(true);     
        fetch(props.aplikasi + '/meja/hapus_meja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        no_meja: no_meja
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_meja();
                    tampil_meja();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("kapasitas").focus();
                    setProses(false);
                }
                else {
                    tampil_meja();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("kapasitas").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_meja();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("kapasitas").focus();
                setProses(false);
            }
            );        
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_meja();
                        setStatusSimpan("Simpan");
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_meja.map((data_meja) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_meja.no_meja}
                                onClick={() => {
                                    setNoMeja(data_meja.no_meja);
                                    setKapasitas(data_meja.kapasitas);
                                    if (data_meja.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }
                                    setStatusSimpan("Perbarui");
                                    setTampilDialog(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: switch_warna_status(data_meja.status),
                                                    }}
                                                >
                                                    {data_meja.no_meja}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{"Kapasitas : " + data_meja.kapasitas}</Typography>}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Meja</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        No. Meja
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="no_meja"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        disabled={status_simpan !== "Simpan" ? true : false}
                                        value={no_meja}
                                        onChange={(event) => setNoMeja(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Kapasitas Meja
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="kapasitas"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={kapasitas}
                                        onChange={(event) => ubah_kapasitas(event)}
                                        onBlur={(event) => perbaiki_kapasitas(event)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >                        
                        <Typography variant="body2" noWrap={true}>{status_simpan}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Meja
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton 
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)} 
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Meja);
import React, { useState, useEffect, forwardRef } from 'react';
import useSound from 'use-sound';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

//gambar
import CheckIcon from '@mui/icons-material/Check';

function Barista(props, ref) {
    //state    
    const [play] = useSound(props.file + "/Musik/notification.mp3");
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [data_pesanan, setDataPesanan] = useState([]);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/dapur/tampil_pesanan_bar.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );

        const interval = setInterval(() => {
            fetch(props.aplikasi + '/dapur/tampil_pesanan_bar.php',
                {
                    method: 'POST',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPesanan(data.data);

                        let awal = 1;
                        let akhir = data.data.length;
                        let dibaca = "sudah";
                        while (awal <= akhir) {
                            if (data.data[awal - 1].dibaca_bar !== "1") {
                                dibaca = "belum";
                                break;
                            }
                            awal++;
                        }

                        if (dibaca === "belum") {
                            play();
                            fetch(props.aplikasi + '/dapur/pesanan_bar_sudah.php',
                                {
                                    method: 'GET',
                                    headers:
                                    {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                .then(response => response.json())
                                .then(data => {

                                })
                                .catch(error => {

                                }
                                );
                        }
                    }
                    else {
                        setDataPesanan([]);
                    }
                })
                .catch(error => {
                    setDataPesanan([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, [props.aplikasi, play]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pesanan = () => {
        fetch(props.aplikasi + '/dapur/tampil_pesanan_bar.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);

                    let awal = 1;
                    let akhir = data.data.length;
                    let dibaca = "sudah";
                    while (awal <= akhir) {
                        if (data.data[awal - 1].dibaca_bar !== "1") {
                            dibaca = "belum";
                            break;
                        }
                        awal++;
                    }

                    if (dibaca === "belum") {
                        play();
                        fetch(props.aplikasi + '/dapur/pesanan_bar_sudah.php',
                            {
                                method: 'GET',
                                headers:
                                {
                                    "Content-type": "application/json; charset=UTF-8"
                                }
                            })
                            .then(response => response.json())
                            .then(data => {

                            })
                            .catch(error => {

                            }
                            );
                    }
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );
    }

    const switch_warna_metode = (param) => {
        switch (param) {
            case "D":
                return "#e3855f";
            case "T":
                return "#de88e0";
            default:
                break;
        }
    }

    const switch_metode = (param) => {
        switch (param) {
            case "D":
                return "Dine-In";
            case "T":
                return "Take Away";
            default:
                break;
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pesanan.map((loop_data_pesanan) => {
                        return (
                            <Grid
                                key={loop_data_pesanan.id_pesanan}
                                item xs={6} sm={4} md={3} lg={2}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: switch_warna_metode(loop_data_pesanan.metode)
                                                    }}
                                                >
                                                    {loop_data_pesanan.no_meja !== "" ? loop_data_pesanan.no_meja : null}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{loop_data_pesanan.nama_booking}</Typography>}
                                                secondary={
                                                    <div>
                                                        <Typography noWrap variant="body2" color="white">{switch_metode(loop_data_pesanan.metode)}</Typography>
                                                        <Typography noWrap variant="body2" color="white">{loop_data_pesanan.pengguna}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                    <Card sx={{ margin: "5px", padding: "5px" }}>
                                        <Grid container item>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox size="small"
                                                            checked={loop_data_pesanan.sudah === "1"}
                                                            onChange={(e) => {
                                                                fetch(props.aplikasi + '/dapur/cek_semua.php',
                                                                    {
                                                                        method: 'POST',
                                                                        body: JSON.stringify
                                                                            ({
                                                                                id_pesanan: loop_data_pesanan.id_pesanan,
                                                                                jenis: "J2",
                                                                                cek: e.target.checked
                                                                            }),
                                                                        headers:
                                                                        {
                                                                            "Content-type": "application/json; charset=UTF-8"
                                                                        }
                                                                    })
                                                                    .then(response => response.json())
                                                                    .then(data => {
                                                                        if (data.kode === 1) {
                                                                            tampil_pesanan();
                                                                        }
                                                                        else {
                                                                            // setDataMeja([]);
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        // setDataMeja([]);
                                                                    }
                                                                    );
                                                            }}
                                                        />
                                                    }
                                                    label="Pilih Semua"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CheckIcon />}
                                                    sx={{ marginBottom: "5px", borderRadius: "5em", backgroundColor: "#37ce9b", }}
                                                    onClick={() => {
                                                        let awal = 1;
                                                        let akhir = loop_data_pesanan.detail.length;
                                                        while (awal <= akhir) {
                                                            if (loop_data_pesanan.detail[awal - 1].status === "0") {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Semua menu wajib sudah ditandai");
                                                                setTampilNotif(true);
                                                                return;
                                                            }
                                                            awal++;
                                                        }

                                                        fetch(props.aplikasi + '/dapur/selesai.php',
                                                            {
                                                                method: 'POST',
                                                                body: JSON.stringify
                                                                    ({
                                                                        id_pesanan: loop_data_pesanan.id_pesanan,
                                                                        jenis: "J2"
                                                                    }),
                                                                headers:
                                                                {
                                                                    "Content-type": "application/json; charset=UTF-8"
                                                                }
                                                            })
                                                            .then(response => response.json())
                                                            .then(data => {
                                                                if (data.kode === 1) {
                                                                    tampil_pesanan();
                                                                }
                                                                else {
                                                                    // setDataMeja([]);
                                                                }
                                                            })
                                                            .catch(error => {
                                                                // setDataMeja([]);
                                                            }
                                                            );
                                                    }}
                                                >
                                                    <Typography variant="body2" noWrap={true}>Selesai</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {loop_data_pesanan.detail.map((data_pesanan_jenis, index) => {
                                            return (
                                                <Grid container item key={index}>
                                                    <Grid item xs={11}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox size="small"
                                                                    checked={data_pesanan_jenis.status === "1"}
                                                                    onChange={(e) => {
                                                                        if (data_pesanan_jenis.status_selesai === "1") {
                                                                            setJenisNotif("warning");
                                                                            setIsiNotif("Menu ini sudah diselesaikan sebelumnya");
                                                                            setTampilNotif(true);
                                                                            return;
                                                                        }

                                                                        fetch(props.aplikasi + '/dapur/cek_masak.php',
                                                                            {
                                                                                method: 'POST',
                                                                                body: JSON.stringify
                                                                                    ({
                                                                                        id_pesanan: loop_data_pesanan.id_pesanan,
                                                                                        id_produk: data_pesanan_jenis.id_produk,
                                                                                        no_tambahan: data_pesanan_jenis.no_tambahan,
                                                                                        cek: e.target.checked
                                                                                    }),
                                                                                headers:
                                                                                {
                                                                                    "Content-type": "application/json; charset=UTF-8"
                                                                                }
                                                                            })
                                                                            .then(response => response.json())
                                                                            .then(data => {
                                                                                if (data.kode === 1) {
                                                                                    tampil_pesanan();
                                                                                }
                                                                                else {
                                                                                    // setDataMeja([]);
                                                                                }
                                                                            })
                                                                            .catch(error => {
                                                                                // setDataMeja([]);
                                                                            }
                                                                            );
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                data_pesanan_jenis.catatan === null || data_pesanan_jenis.catatan === ""
                                                                    ?
                                                                    data_pesanan_jenis.nama
                                                                    :
                                                                    data_pesanan_jenis.nama + " (" + data_pesanan_jenis.catatan + ")"
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Typography variant="body1" align="right">
                                                            {data_pesanan_jenis.jumlah}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Card>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Barista);
import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import { Doughnut, Line } from 'react-chartjs-2';

//gambar
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
// import TrendingDownIcon from '@mui/icons-material/TrendingDown';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import GroupsIcon from '@mui/icons-material/Groups';
// import { height } from '@mui/system';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

// const data = {
//     labels: [
//         'Red',
//         'Green',
//         'Yellow'
//     ],
//     datasets: [{
//         data: [300, 50, 100],
//         backgroundColor: [
//             '#FF6384',
//             '#36A2EB',
//             '#FFCE56'
//         ],
//         hoverBackgroundColor: [
//             '#FF6384',
//             '#36A2EB',
//             '#FFCE56'
//         ]
//     }]
// };

// const data2 = {
//     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//     datasets: [
//         {
//             label: 'Dataset of Months',
//             fill: false,
//             lineTension: 0.1,
//             backgroundColor: 'rgba(75,192,192,0.4)',
//             borderColor: 'rgba(75,192,192,1)',
//             borderCapStyle: 'butt',
//             borderDash: [],
//             borderDashOffset: 0.0,
//             borderJoinStyle: 'miter',
//             pointBorderColor: 'rgba(75,192,192,1)',
//             pointBackgroundColor: '#fff',
//             pointBorderWidth: 1,
//             pointHoverRadius: 5,
//             pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//             pointHoverBorderColor: 'rgba(220,220,220,1)',
//             pointHoverBorderWidth: 2,
//             pointRadius: 1,
//             pointHitRadius: 10,
//             data: [65, 59, 80, 81, 56, 55, 40]
//         }
//     ]
// };

function Beranda(props, ref) {
    //state    
    const [pendapatan_hari, setPendapatanHari] = useState(0);
    const [pendapatan_bulan, setPendapatanBulan] = useState(0);
    const [pesanan_hari, setPesananHari] = useState(0);
    const [pesanan_bulan, setPesananBulan] = useState(0);
    const [aset_bahan, setAsetBahan] = useState(0);
    const [aset_alat, setAsetAlat] = useState(0);
    const [kerugian_hari, setKerugianHari] = useState(0);
    const [kerugian_bulan, setKerugianBulan] = useState(0);
    const [data_terlaris_makanan_hari, setDataTerlarisMakananHari] = useState([]);
    const [data_terlaris_minuman_hari, setDataTerlarisMinumanHari] = useState([]);
    const [data_terlaris_makanan_bulan, setDataTerlarisMakananBulan] = useState([]);
    const [data_terlaris_minuman_bulan, setDataTerlarisMinumanBulan] = useState([]);
    const [data_habis, setDataHabis] = useState([]);
    const [data_bahan_habis, setDataBahanHabis] = useState([]);
    const [data_alat_habis, setDataAlatHabis] = useState([]);
    const [belanja_bahan_hari, setBelanjaBahanHari] = useState(0);
    const [belanja_bahan_bulan, setBelanjaBahanBulan] = useState(0);
    const [belanja_alat_hari, setBelanjaAlatHari] = useState(0);
    const [belanja_alat_bulan, setBelanjaAlatBulan] = useState(0);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/admin/tampil_beranda.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setPendapatanHari(data.data[0].pendapatan_hari);
                    setPendapatanBulan(data.data[0].pendapatan_bulan);
                    setPesananHari(data.data[0].pesanan_hari);
                    setPesananBulan(data.data[0].pesanan_bulan);
                    setAsetBahan(data.data[0].aset_bahan);
                    setAsetAlat(data.data[0].aset_alat);
                    setKerugianHari(data.data[0].kerugian_hari);
                    setKerugianBulan(data.data[0].kerugian_bulan);
                    setDataTerlarisMakananHari(data.data[0].terlaris_makanan_hari);
                    setDataTerlarisMinumanHari(data.data[0].terlaris_minuman_hari);
                    setDataTerlarisMakananBulan(data.data[0].terlaris_makanan_bulan);
                    setDataTerlarisMinumanBulan(data.data[0].terlaris_minuman_bulan);
                    setDataHabis(data.data[0].habis);
                    setDataBahanHabis(data.data[0].bahan_habis);
                    setDataAlatHabis(data.data[0].alat_habis);
                    setBelanjaBahanHari(data.data[0].belanja_bahan_hari);
                    setBelanjaBahanBulan(data.data[0].belanja_bahan_bulan);
                    setBelanjaAlatHari(data.data[0].belanja_alat_hari);
                    setBelanjaAlatBulan(data.data[0].belanja_alat_bulan);
                }
                else {
                    setPendapatanHari(0);
                    setPendapatanBulan(0);
                    setPesananHari(0);
                    setPesananBulan(0);
                    setAsetBahan(0);
                    setAsetAlat(0);
                    setKerugianHari(0);
                    setKerugianBulan(0);
                    setDataTerlarisMakananHari([]);
                    setDataTerlarisMinumanHari([]);
                    setDataTerlarisMakananBulan([]);
                    setDataTerlarisMinumanBulan([]);
                    setDataHabis([]);
                    setDataBahanHabis([]);
                    setDataAlatHabis([]);
                    setBelanjaBahanHari([]);
                    setBelanjaBahanBulan([]);
                    setBelanjaAlatHari([]);
                    setBelanjaAlatBulan([]);
                }
            })
            .catch(error => {
                setPendapatanHari(0);
                setPendapatanBulan(0);
                setPesananHari(0);
                setPesananBulan(0);
                setAsetBahan(0);
                setAsetAlat(0);
                setKerugianHari(0);
                setKerugianBulan(0);
                setDataTerlarisMakananHari([]);
                setDataTerlarisMinumanHari([]);
                setDataTerlarisMakananBulan([]);
                setDataTerlarisMinumanBulan([]);
                setDataHabis([]);
                setDataBahanHabis([]);
                setDataAlatHabis([]);
                setBelanjaBahanHari([]);
                setBelanjaBahanBulan([]);
                setBelanjaAlatHari([]);
                setBelanjaAlatBulan([]);
            }
            );

        setInterval(() => {
            fetch(props.aplikasi + '/admin/tampil_beranda.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setPendapatanHari(data.data[0].pendapatan_hari);
                        setPendapatanBulan(data.data[0].pendapatan_bulan);
                        setPesananHari(data.data[0].pesanan_hari);
                        setPesananBulan(data.data[0].pesanan_bulan);
                        setAsetBahan(data.data[0].aset_bahan);
                        setAsetAlat(data.data[0].aset_alat);
                        setKerugianHari(data.data[0].kerugian_hari);
                        setKerugianBulan(data.data[0].kerugian_bulan);
                        setDataTerlarisMakananHari(data.data[0].terlaris_makanan_hari);
                        setDataTerlarisMinumanHari(data.data[0].terlaris_minuman_hari);
                        setDataTerlarisMakananBulan(data.data[0].terlaris_makanan_bulan);
                        setDataTerlarisMinumanBulan(data.data[0].terlaris_minuman_bulan);
                        setDataHabis(data.data[0].habis);
                        setDataBahanHabis(data.data[0].bahan_habis);
                        setDataAlatHabis(data.data[0].alat_habis);
                        setBelanjaBahanHari(data.data[0].belanja_bahan_hari);
                        setBelanjaBahanBulan(data.data[0].belanja_bahan_bulan);
                        setBelanjaAlatHari(data.data[0].belanja_alat_hari);
                        setBelanjaAlatBulan(data.data[0].belanja_alat_bulan);
                    }
                    else {
                        setPendapatanHari(0);
                        setPendapatanBulan(0);
                        setPesananHari(0);
                        setPesananBulan(0);
                        setAsetBahan(0);
                        setAsetAlat(0);
                        setKerugianHari(0);
                        setKerugianBulan(0);
                        setDataTerlarisMakananHari([]);
                        setDataTerlarisMinumanHari([]);
                        setDataTerlarisMakananBulan([]);
                        setDataTerlarisMinumanBulan([]);
                        setDataHabis([]);
                        setDataBahanHabis([]);
                        setDataAlatHabis([]);
                        setBelanjaBahanHari([]);
                        setBelanjaBahanBulan([]);
                        setBelanjaAlatHari([]);
                        setBelanjaAlatBulan([]);
                    }
                })
                .catch(error => {
                    setPendapatanHari(0);
                    setPendapatanBulan(0);
                    setPesananHari(0);
                    setPesananBulan(0);
                    setAsetBahan(0);
                    setAsetAlat(0);
                    setKerugianHari(0);
                    setKerugianBulan(0);
                    setDataTerlarisMakananHari([]);
                    setDataTerlarisMinumanHari([]);
                    setDataTerlarisMakananBulan([]);
                    setDataTerlarisMinumanBulan([]);
                    setDataHabis([]);
                    setDataBahanHabis([]);
                    setDataAlatHabis([]);
                    setBelanjaBahanHari([]);
                    setBelanjaBahanBulan([]);
                    setBelanjaAlatHari([]);
                    setBelanjaAlatBulan([]);
                }
                );
        }, 10000)
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    return (
        <div>
            <Grid container spacing={1} sx={{ padding: "10px" }}>
                <Grid
                    item xs={12} sm={6} md={3}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#51ba16" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Pendapatan
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <AttachMoneyIcon
                                    sx={{
                                        color: "white",
                                        padding: "0"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pendapatan_hari))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pendapatan_bulan))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={3}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#ff7c5d" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Total Pesanan
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <FastfoodIcon
                                    sx={{
                                        color: "white"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pesanan_hari))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pesanan_bulan))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={3}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#de88df" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Total Aset
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <FoodBankIcon
                                    sx={{
                                        color: "white"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bahan Baku
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(aset_bahan))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Peralatan
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(aset_alat))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={3}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#acafb4" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Total Kerugian
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <BrokenImageIcon
                                    sx={{
                                        color: "white"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(kerugian_hari))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(kerugian_bulan))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={4}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "white" }}
                    >
                        <Typography variant="h6">
                            Produk Terlaris
                        </Typography>
                        <Divider />
                        <br />

                        <Grid
                            container
                        >
                            <Grid
                                item xs={5}
                            >
                                <Typography variant="h6">
                                    Hari Ini
                                </Typography>
                                <Grid container item spacing={1}>
                                    <Grid container item xs={12}>
                                        <Typography variant="body1"
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "#baa8e6",
                                                color: "white"
                                            }}
                                        >
                                            Makanan
                                        </Typography>
                                    </Grid>
                                    {data_terlaris_makanan_hari.map((data_terlaris_makanan, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris_makanan.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris_makanan.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}

                                    <Grid container item xs={12}>
                                        <Typography variant="body1"
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "#baa8e6",
                                                color: "white"
                                            }}
                                        >
                                            Minuman
                                        </Typography>
                                    </Grid>
                                    {data_terlaris_minuman_hari.map((data_terlaris_minuman, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris_minuman.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris_minuman.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            <Grid
                                // container alignItems="center" justifyContent="flex-end"
                                // item xs={2}
                                container item xs={1} justifyContent="center"
                            >
                                <Divider orientation='vertical' variant='middle' />
                            </Grid>

                            <Grid
                                item xs={6}
                            >
                                <Typography variant="h6">
                                    Bulan Ini
                                </Typography>
                                <Grid container item spacing={1}>
                                    <Grid container item xs={12}>
                                        <Typography variant="body1"
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "#baa8e6",
                                                color: "white"
                                            }}
                                        >
                                            Makanan
                                        </Typography>
                                    </Grid>
                                    {data_terlaris_makanan_bulan.map((data_terlaris_makanan, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris_makanan.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris_makanan.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}

                                    <Grid container item xs={12}>
                                        <Typography variant="body1"
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "#baa8e6",
                                                color: "white"
                                            }}
                                        >
                                            Minuman
                                        </Typography>
                                    </Grid>
                                    {data_terlaris_minuman_bulan.map((data_terlaris_minuman, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris_minuman.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris_minuman.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={4}
                >
                    <Grid
                        container spacing={1}
                    >
                        <Grid
                            item xs={12}
                        >
                            <Card
                                sx={{ padding: "10px", backgroundColor: "white" }}
                            >
                                <Typography variant="h6">
                                    Produk Habis
                                </Typography>
                                <Divider />
                                <br />
                                <Grid container item spacing={1}>
                                    {data_habis.map((data_habis, index) => {
                                        return (
                                            <Grid key={index} item xs={12}>
                                                <Typography variant="body2">
                                                    {data_habis.nama}
                                                </Typography>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Card>
                        </Grid>

                        <Grid
                            item xs={12}
                        >
                            <Card
                                sx={{ padding: "10px", backgroundColor: "white" }}
                            >
                                <Typography variant="h6">
                                    Belanja
                                </Typography>
                                <Divider />
                                <br />
                                <Grid container item spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            Bahan Hari Ini
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Rp. {format_rupiah(parseInt(belanja_bahan_hari))}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            Bahan Bulan Ini
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Rp. {format_rupiah(parseInt(belanja_bahan_bulan))}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            Alat Hari Ini
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Rp. {format_rupiah(parseInt(belanja_alat_hari))}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            Alat Bulan Ini
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Rp. {format_rupiah(parseInt(belanja_alat_bulan))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={4}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "white" }}
                    >
                        <Typography variant="h6">
                            Stok Bahan & Peralatan Habis
                        </Typography>
                        <Divider />
                        <br />

                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center"><Typography variant="caption">Bahan</Typography></StyledTableCell>
                                        <StyledTableCell align="center"><Typography variant="caption">Satuan</Typography></StyledTableCell>
                                        <StyledTableCell align="center"><Typography variant="caption">Stok Minimal</Typography></StyledTableCell>
                                        <StyledTableCell align="center"><Typography variant="caption">Stok</Typography></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_bahan_habis.map((data_bahan_habis, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_bahan_habis.nama}</Typography></TableCell>
                                                <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_bahan_habis.satuan}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_bahan_habis.stok_minimal}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_bahan_habis.jumlah}</Typography></TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br />

                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center"><Typography variant="caption">Peralatan</Typography></StyledTableCell>
                                        <StyledTableCell align="center"><Typography variant="caption">Satuan</Typography></StyledTableCell>
                                        <StyledTableCell align="center"><Typography variant="caption">Stok Minimal</Typography></StyledTableCell>
                                        <StyledTableCell align="center"><Typography variant="caption">Stok</Typography></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_alat_habis.map((data_alat_habis, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_alat_habis.nama}</Typography></TableCell>
                                                <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_alat_habis.satuan}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_alat_habis.stok_minimal}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_alat_habis.jumlah}</Typography></TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default forwardRef(Beranda);
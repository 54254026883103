import React, { Component, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

class Cetak extends Component {
    render = () => {
        return (
            <div style={{ margin: "20px" }}>
                <Grid container spacing={2}>                    
                    <Grid item xs={8}>
                        <center>
                            <p style={{ margin: 0, fontWeight: "bold", fontSize: "18px" }}>
                                SURAT ELIGIBILITAS PESERTA
                            </p>
                            <p style={{ margin: 0, fontWeight: "bold", fontSize: "18px" }}>
                                RSUD BENDAN KOTA PEKALONGAN
                            </p>
                        </center>
                    </Grid>                    
                </Grid>

                {/* <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid container item xs={7}>
                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontWeight: "bold", fontSize: "16px" }}>
                                    No. SEP
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontWeight: "bold", fontSize: "16px" }}>
                                    : {this.props.sep}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Tgl. SEP
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.tgl_sep}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    No. Kartu
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.no_kartu + " No. MR : " + this.props.norm}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Nama Peserta
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.nama}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Tgl. Lahir
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.tgl_lahir}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Jenis Kelamin
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.jk === "L" ? "Laki-Laki" : "Perempuan"}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Poli Tujuan
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.poli}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Asal Faskes Tk I
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.faskes}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Diagnosa Awal
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.diagnosa}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Catatan
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.catatan}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={5}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontWeight: "bold", fontSize: "20px" }}>
                                        {this.props.no_antri}
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        {this.props.prb}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Peserta
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        : {this.props.peserta}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        COB
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        :
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Jenis Rawat
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        : Rawat Jalan
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Kelas Rawat
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        : 3
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Penjamin
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        :
                                    </p>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginTop: "10px"
                                    }}
                                >
                                    <center>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            Pasien / Keluarga Pasien
                                        </p>
                                    </center>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginTop: "50px"
                                    }}
                                >
                                    <center>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            (......................................................................)
                                        </p>
                                    </center>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <p
                            style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                margin: "0px"
                            }}
                        >
                            *Saya menyetujui BPJS Kesehatan menggunakan informasi Medis Pasien jika diperlukan
                        </p>

                        <p
                            style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                margin: "0px"
                            }}
                        >
                            *SEP bukan bukti penjamin peserta
                        </p>

                        <p
                            style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                margin: "0px"
                            }}
                        >
                            {this.props.cetakan}
                        </p>
                    </Grid>
                </Grid>

                <hr />

                <Grid item xs={12}>
                    <center>
                        <p style={{ margin: 0, fontWeight: "bold", fontSize: "18px" }}>
                            RESUME MEDIS RAWAT JALAN / IGD
                        </p>
                    </center>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid container item xs={7}>
                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    Anamnesis
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    :
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    Pemeriksaan Fisik
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    :
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    Diagnosis
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    :
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    Tindakan
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    :
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    Terapi Obat
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    :
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    Tindak Lanjut
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, marginBottom: "10px", fontSize: "12px" }}>
                                    :
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid container item xs={7}>
                    </Grid>
                    <Grid container item xs={5}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <center>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            Tanda Tangan
                                        </p>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            Dokter Penanggung Jawab Pasien
                                        </p>
                                    </center>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginTop: "50px"
                                    }}
                                >
                                    <center>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            (......................................................................)
                                        </p>
                                    </center>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <hr />

                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <img
                            src={this.props.basename + "/PICTURES/bpjs.png"}
                            alt="bpjs.png"
                            width="160px"
                            style={{
                                position: "relative"
                            }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <center>
                            <p style={{ margin: 0, fontWeight: "bold", fontSize: "18px" }}>
                                SURAT ELIGIBILITAS PESERTA
                            </p>
                            <p style={{ margin: 0, fontWeight: "bold", fontSize: "18px" }}>
                                RSUD BENDAN KOTA PEKALONGAN
                            </p>
                        </center>
                    </Grid>

                    <Grid item xs={2}>
                        <img
                            src={this.props.basename + "/PICTURES/kars.png"}
                            alt="kars.png"
                            width="90px"
                            style={{
                                position: "relative"
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid container item xs={7}>
                        <Grid container>
                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontWeight: "bold", fontSize: "16px" }}>
                                    No. SEP
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontWeight: "bold", fontSize: "16px" }}>
                                    : {this.props.sep}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Tgl. SEP
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.tgl_sep}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    No. Kartu
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.no_kartu + " No. MR : " + this.props.norm}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Nama Peserta
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.nama}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Tgl. Lahir
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.tgl_lahir}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Jenis Kelamin
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.jk === "L" ? "Laki-Laki" : "Perempuan"}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Poli Tujuan
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.poli}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Asal Faskes Tk I
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.faskes}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Diagnosa Awal
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.diagnosa}
                                </p>
                            </Grid>

                            <Grid item xs={3}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    Catatan
                                </p>
                            </Grid>
                            <Grid item xs={9}>
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                    : {this.props.catatan}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={5}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontWeight: "bold", fontSize: "20px" }}>
                                        {this.props.no_antri}
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        {this.props.prb}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Peserta
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        : {this.props.peserta}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        COB
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        :
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Jenis Rawat
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        : Rawat Jalan
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Kelas Rawat
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        : 3
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        Penjamin
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p style={{ margin: 0, fontSize: "12px" }}>
                                        :
                                    </p>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginTop: "10px"
                                    }}
                                >
                                    <center>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            Pasien / Keluarga Pasien
                                        </p>
                                    </center>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        marginTop: "50px"
                                    }}
                                >
                                    <center>
                                        <p style={{ margin: 0, fontSize: "12px" }}>
                                            (......................................................................)
                                        </p>
                                    </center>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <p
                            style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                margin: "0px"
                            }}
                        >
                            *Saya menyetujui BPJS Kesehatan menggunakan informasi Medis Pasien jika diperlukan
                        </p>

                        <p
                            style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                margin: "0px"
                            }}
                        >
                            *SEP bukan bukti penjamin peserta
                        </p>

                        <p
                            style={{
                                fontSize: "10px",
                                fontStyle: "italic",
                                margin: "0px"
                            }}
                        >
                            {this.props.cetakan}
                        </p>
                    </Grid>
                </Grid> */}
            </div>
        );
    }
}

export default Cetak;
import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

const Input = styled('input')({
    display: 'none',
});

function Jenis_Produk(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_jenis, setSumberJenis] = useState([]);
    const [data_jenis, setDataJenis] = useState([]);
    const [id_jenis, setIdJenis] = useState("");
    const [nama, setNama] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampung_foto, setTampungFoto] = useState(null);
    const [foto, setFoto] = useState(null);
    const [jenis_file, setJenisFile] = useState("");
    const [nama_foto, setNamaFoto] = useState("");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/jenis_produk/tampil_jenis_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberJenis(data.data);
                    setDataJenis(data.data);
                }
                else {
                    setSumberJenis([]);
                    setDataJenis([]);
                }
            })
            .catch(error => {
                setSumberJenis([]);
                setDataJenis([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_jenis = () => {
        fetch(props.aplikasi + '/jenis_produk/tampil_jenis_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberJenis(data.data);
                    setDataJenis(data.data);
                }
                else {
                    setSumberJenis([]);
                    setDataJenis([]);
                }
            })
            .catch(error => {
                setSumberJenis([]);
                setDataJenis([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_jenis.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_jenis[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_jenis[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_jenis[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataJenis(tampung);
    }

    const bersih_input_jenis = () => {
        setIdJenis("");
        setNama("");
        setStatus(false);
        setLabelStatus("Nonaktif");
        setTampungFoto(null);
        setFoto(null);
        setJenisFile("");
        setNamaFoto("");
    }

    const simpan = () => {
        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama jenis produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (id_jenis.trim() === "J1" || id_jenis.trim() === "J2") {
            setJenisNotif("warning");
            setIsiNotif("Jenis produk makanan & minuman tidak bisa dimodifikasi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        setProses(true);
        if (id_jenis === "") {
            fetch(props.aplikasi + '/jenis_produk/simpan_jenis_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_jenis: "",
                            nama: nama,
                            status: status,
                            foto: foto,
                            jenis_file: jenis_file
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_jenis();
                        tampil_jenis();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_jenis();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_jenis();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/jenis_produk/simpan_jenis_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_jenis: id_jenis,
                            nama: nama,
                            status: status,
                            foto: foto,
                            jenis_file: jenis_file
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_jenis();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_jenis();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_jenis();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/jenis_produk/hapus_jenis_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_jenis: id_jenis
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_jenis();
                    tampil_jenis();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                else {
                    tampil_jenis();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_jenis();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_jenis();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan jenis produk"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_jenis.map((data_jenis) => {
                        return (
                            <Grid
                                item xs={6} md={3} lg={2}
                                key={data_jenis.id_jenis}
                                onClick={() => {
                                    bersih_input_jenis();
                                    setIdJenis(data_jenis.id_jenis);
                                    setNama(data_jenis.nama);
                                    if (data_jenis.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }
                                    setNamaFoto(data_jenis.foto);
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <ImageListItem>
                                        <CardMedia
                                            component="img"
                                            src={props.file + "/Foto/Jenis Produk/" + data_jenis.foto}
                                            sx={{
                                                backgroundColor: "gray",
                                                width: "400px",
                                                height: "200px"
                                            }}
                                        />
                                        <ImageListItemBar
                                            title={data_jenis.nama}
                                        />
                                    </ImageListItem>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Jenis Produk</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <center>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <label htmlFor="icon-button-file">
                                        <Input accept="image/*" id="icon-button-file" type="file"
                                            onChange={(e) => {
                                                setFoto(e.target.files[0]);
                                                setTampungFoto(e.target.files[0]);
                                                setJenisFile(e.target.files[0].name.split(".").pop());
                                                let reader = new FileReader();
                                                reader.readAsDataURL(e.target.files[0]);
                                                reader.onload = () => {
                                                    setFoto(reader.result);
                                                }
                                            }}
                                        />
                                        <Fab
                                            size="small"
                                            color="primary"
                                            component="span"
                                        >
                                            <PhotoCamera />
                                        </Fab>
                                    </label>
                                }
                            >
                                <CardMedia
                                    component="img"
                                    src={tampung_foto !== null ? URL.createObjectURL(tampung_foto) : props.file + "/Foto/Jenis Produk/" + nama_foto}
                                    sx={{
                                        backgroundColor: "gray",                                        
                                        width: "250px",
                                        height: "200px"
                                    }}
                                />
                            </Badge>
                        </center>
                        <br />

                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Nama Jenis Produk
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={8}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={8}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_jenis === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Jenis Produk
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton 
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)} 
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Jenis_Produk);
import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Pemakaian(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_peralatan, setSumberPeralatan] = useState([]);
    const [sumber_peralatan2, setSumberPeralatan2] = useState([]);
    const [data_peralatan, setDataPeralatan] = useState([]);
    const [nama] = useState("");
    const [habis, setHabis] = useState(false);
    const [label_habis, setLabelHabis] = useState("Tersedia");
    const [data_input_peralatan, setDataInputPeralatan] = useState([]);
    const [total, setTotal] = useState(0);

    const [jumlah_baris] = useState(10);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(0);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/peralatan/tampil_peralatan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPeralatan(data.data);
                    setSumberPeralatan2(data.data);
                    setJumlahHalaman(Math.ceil(data.data.length / jumlah_baris));
                    setHalaman(1);
                    let awal = 1;
                    let akhir = 0;
                    if (data.data.length > 10) {
                        akhir = 10;
                    }
                    else {
                        akhir = data.data.length;
                    }
                    let tampung = [];
                    while (awal <= akhir) {
                        tampung.push(data.data[awal - 1]);
                        awal++;
                    }

                    setDataPeralatan(tampung);
                }
                else {
                    setSumberPeralatan([]);
                    setSumberPeralatan2([]);
                    setJumlahHalaman(0);
                    setHalaman(0);
                    setDataPeralatan([]);
                }
            })
            .catch(error => {
                setSumberPeralatan([]);
                setSumberPeralatan2([]);
                setJumlahHalaman(0);
                setHalaman(0);
                setDataPeralatan([]);
            }
            );
    }, [props.aplikasi, jumlah_baris]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_produk = (id_jenis) => {
        fetch(props.aplikasi + '/peralatan/tampil_peralatan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPeralatan(data.data);
                    setSumberPeralatan2(data.data);
                    setDataPeralatan(data.data);
                }
                else {
                    setSumberPeralatan([]);
                    setSumberPeralatan2([]);
                    setDataPeralatan([]);
                }
            })
            .catch(error => {
                setSumberPeralatan([]);
                setSumberPeralatan2([]);
                setDataPeralatan([]);
            }
            );
    }

    const bersih_input_habis = () => {
        setDataInputPeralatan([]);
        setTotal(0);
    }

    const tambah_pesanan = (id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) + 1 > parseFloat(tampung[awal - 1]["kapasitas"])) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const kurang_pesanan = (id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(parseFloat(tampung[awal - 1]["subtotal"]));
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const ubah_jumlah_pesanan = (event, id_alat) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_jumlah_pesanan = (event, id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                if (parseFloat(event.target.value) > 0) {
                    if (parseFloat(event.target.value) > parseFloat(tampung[awal - 1]["kapasitas"])) {
                        tampung[awal - 1]["jumlah"] = tampung[awal - 1]["kapasitas"];
                    }
                    else {
                        tampung[awal - 1]["jumlah"] = event.target.value;
                    }
                }
                else {
                    if (parseFloat(tampung[awal - 1]["kapasitas"]) >= 1) {
                        tampung[awal - 1]["jumlah"] = 1;
                    }
                    else {
                        tampung[awal - 1]["jumlah"] = tampung[awal - 1]["kapasitas"];
                    }
                }
                tampung[awal - 1]["subtotal"] = tampung[awal - 1]["jumlah"] * tampung[awal - 1]["harga_beli"];
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const hapus_pesanan = (id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung[awal - 1]["no"] = awal;
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_peralatan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_peralatan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_peralatan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_peralatan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setSumberPeralatan2(tampung);
        setJumlahHalaman(Math.ceil(tampung.length / jumlah_baris));
        setHalaman(1);
        awal = 1;
        akhir = 0;
        if (tampung.length > 10) {
            akhir = 10;
        }
        else {
            akhir = tampung.length;
        }
        let tampung2 = [];
        while (awal <= akhir) {
            tampung2.push(tampung[awal - 1]);
            awal++;
        }

        setDataPeralatan(tampung2);
    }

    const pindah_halaman = (halaman_baru) => {
        setHalaman(halaman_baru);
        let awal = (halaman_baru * jumlah_baris) - jumlah_baris + 1;
        let akhir = halaman_baru * jumlah_baris;
        let tampung = [];
        while (awal <= akhir) {
            if (awal <= sumber_peralatan2.length) {
                tampung.push(sumber_peralatan2[awal - 1]);
            }
            awal++;
        }

        setDataPeralatan(tampung);
    }

    const simpan = () => {
        if (data_input_peralatan.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Bahan baku belum dipilih");
            setTampilNotif(true);
            return;
        }

        fetch(props.aplikasi + '/peralatan/simpan_pemakaian.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        data_pemakaian: data_input_peralatan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_habis();
                    tampil_produk();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Box>
                    <Grid container spacing={1} sx={{ padding: "10px" }}>
                        <Grid item xs={12} sm={8}>
                            <Grid item xs={12}>
                                <Card sx={{ padding: "10px" }}>
                                    <Typography variant="h6">
                                        Daftar Pemakaian
                                    </Typography>
                                    <Divider />

                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center"><Typography variant="caption">Nama</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Jumlah</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Kapasitas</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Satuan</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Nilai</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Aksi</Typography></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_input_peralatan.map((data_input_peralatan) => {
                                                    return (
                                                        <StyledTableRow key={data_input_peralatan.id_alat}>
                                                            <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_input_peralatan.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_pesanan(data_input_peralatan.id_alat)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "center", width: "50px" }
                                                                    }}
                                                                    value={data_input_peralatan.jumlah}
                                                                    onChange={(e) => ubah_jumlah_pesanan(e, data_input_peralatan.id_alat)}
                                                                    onBlur={(e) => perbaiki_jumlah_pesanan(e, data_input_peralatan.id_alat)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_pesanan(data_input_peralatan.id_alat)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_input_peralatan.kapasitas}</Typography></TableCell>
                                                            <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_input_peralatan.satuan}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_input_peralatan.harga_beli2}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_pesanan(data_input_peralatan.id_alat)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell align="right" colSpan={4}>
                                                        <Typography variant="h6">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveOutlined />}
                                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                        onClick={() => simpan()}
                                    >
                                        Simpan
                                    </Button>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid
                                    item xs={12}
                                    key={data_peralatan.id_alat}
                                >
                                    <Card
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Cari berdasarkan nama alat"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchOutlined />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={cari}
                                        />
                                    </Card>
                                </Grid>

                                <Grid
                                    item xs={12}
                                    key={data_peralatan.id_alat}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>

                                {data_peralatan.map((data_peralatan) => {
                                    return (
                                        <Grid
                                            item xs={12}
                                            key={data_peralatan.id_alat}
                                            onClick={() => {
                                                var tampung = [];
                                                var awal, akhir = 0;
                                                if (data_input_peralatan.length > 0) {
                                                    tampung = data_input_peralatan;
                                                    awal = 1;
                                                    akhir = tampung.length;
                                                    var cari = "";
                                                    while (awal <= akhir) {
                                                        if (tampung[awal - 1]["id_alat"] === data_peralatan.id_alat) {
                                                            if (parseFloat(tampung[awal - 1]["jumlah"]) + 1 > tampung[awal - 1]["kapasitas"]) {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Stok bahan ini sudah habis");
                                                                setTampilNotif(true);
                                                                return;
                                                            }
                                                            tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                                            tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseInt(tampung[awal - 1]["harga_beli"]);
                                                            tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                            cari = "ada";
                                                            break;
                                                        }
                                                        awal++;
                                                    }

                                                    if (cari === "") {
                                                        if (parseFloat(data_peralatan.jumlah) < 1 && parseFloat(data_peralatan.jumlah) > 0) {
                                                            tampung.push({ "id_alat": data_peralatan.id_alat, "nama": data_peralatan.nama, "harga_beli": data_peralatan.harga_beli, "harga_beli2": data_peralatan.harga_beli2, "jumlah": data_peralatan.jumlah, "kapasitas": data_peralatan.jumlah, "satuan": data_peralatan.satuan, "subtotal": data_peralatan.harga_beli, "subtotal2": data_peralatan.harga_beli2 });
                                                        }
                                                        else if (parseFloat(data_peralatan.jumlah) === 0) {
                                                            setJenisNotif("warning");
                                                            setIsiNotif("Stok bahan ini sudah habis");
                                                            setTampilNotif(true);
                                                            return;
                                                        }
                                                        else {
                                                            tampung.push({ "id_alat": data_peralatan.id_alat, "nama": data_peralatan.nama, "harga_beli": data_peralatan.harga_beli, "harga_beli2": data_peralatan.harga_beli2, "jumlah": 1, "kapasitas": data_peralatan.jumlah, "satuan": data_peralatan.satuan, "subtotal": data_peralatan.harga_beli, "subtotal2": data_peralatan.harga_beli2 });
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (parseFloat(data_peralatan.jumlah) < 1 && parseFloat(data_peralatan.jumlah) > 0) {
                                                        tampung.push({ "id_alat": data_peralatan.id_alat, "nama": data_peralatan.nama, "harga_beli": data_peralatan.harga_beli, "harga_beli2": data_peralatan.harga_beli2, "jumlah": data_peralatan.jumlah, "kapasitas": data_peralatan.jumlah, "satuan": data_peralatan.satuan, "subtotal": data_peralatan.harga_beli, "subtotal2": data_peralatan.harga_beli2 });
                                                    }
                                                    else if (parseFloat(data_peralatan.jumlah) === 0) {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Stok bahan ini sudah habis");
                                                        setTampilNotif(true);
                                                        return;
                                                    }
                                                    else {
                                                        tampung.push({ "id_alat": data_peralatan.id_alat, "nama": data_peralatan.nama, "harga_beli": data_peralatan.harga_beli, "harga_beli2": data_peralatan.harga_beli2, "jumlah": 1, "kapasitas": data_peralatan.jumlah, "satuan": data_peralatan.satuan, "subtotal": data_peralatan.harga_beli, "subtotal2": data_peralatan.harga_beli2 });
                                                    }
                                                }

                                                var total = 0;
                                                awal = 1;
                                                akhir = tampung.length;
                                                while (awal <= akhir) {
                                                    total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                    awal++;
                                                }

                                                setDataInputPeralatan(tampung);
                                                setTotal(total);
                                            }}
                                        >
                                            <Card
                                                sx={
                                                    parseFloat(data_peralatan.jumlah) <= parseFloat(data_peralatan.stok_minimal)
                                                        ? {
                                                            backgroundColor: "#f99f87"
                                                        }
                                                        : {}
                                                }
                                            >
                                                <List>
                                                    <ListItem button>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body1">{data_peralatan.nama}</Typography>
                                                            }
                                                        >
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Card>
                                        </Grid>
                                    );
                                })}

                                <Grid
                                    item xs={12}
                                    key={data_peralatan.id_alat}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Stok Habis</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4}>
                                    <Typography variant="body2">
                                        Nama Produk
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {nama}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4}>
                                    <Typography variant="body2">
                                        Stok Habis
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={habis}
                                                onChange={(event) => {
                                                    if (habis === false) {
                                                        setHabis(true);
                                                        setLabelHabis("Habis");
                                                    }
                                                    else {
                                                        setHabis(false);
                                                        setLabelHabis("Tersedia");
                                                    }
                                                }}
                                            />
                                        }

                                        label={
                                            <Typography variant="body2">
                                                {label_habis}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        Simpan
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialog(false)}
                    >
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default forwardRef(Pemakaian);
import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import HistoryIcon from '@mui/icons-material/History';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Kerugian(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [sumber_peralatan, setSumberPeralatan] = useState([]);
    const [sumber_peralatan2, setSumberPeralatan2] = useState([]);
    const [data_peralatan, setDataPeralatan] = useState([]);
    const [tanggal_kejadian, setTanggalKejadian] = useState(new Date());
    const [data_karyawan, setDataKaryawan] = useState([]);
    const [karyawan, setKaryawan] = useState(null);
    const [input_karyawan, setInputKaryawan] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [data_input_peralatan, setDataInputPeralatan] = useState([]);
    const [total, setTotal] = useState(0);

    const [jumlah_baris] = useState(10);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(0);

    const [nama_alat, setNamaAlat] = useState("");
    const [data_riwayat, setDataRiwayat] = useState([]);
    const [tampil_dialog_riwayat, setTampilDialogRiwayat] = useState(false);

    //fungsi
    useEffect(() => {
        setJenisNotif("info");
        setIsiNotif("Transaksi akan mengurangi saldo peralatan beserta jumlahnya");
        setTampilNotif(true);
        
        fetch(props.aplikasi + '/peralatan/tampil_peralatan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPeralatan(data.data);
                    setSumberPeralatan2(data.data);
                    setJumlahHalaman(Math.ceil(data.data.length / jumlah_baris));
                    setHalaman(1);
                    let awal = 1;
                    let akhir = 0;
                    if (data.data.length > 10) {
                        akhir = 10;
                    }
                    else {
                        akhir = data.data.length;
                    }
                    let tampung = [];
                    while (awal <= akhir) {
                        tampung.push(data.data[awal - 1]);
                        awal++;
                    }

                    setDataPeralatan(tampung);
                }
                else {
                    setSumberPeralatan([]);
                    setSumberPeralatan2([]);
                    setJumlahHalaman(0);
                    setHalaman(0);
                    setDataPeralatan([]);
                }
            })
            .catch(error => {
                setSumberPeralatan([]);
                setSumberPeralatan2([]);
                setJumlahHalaman(0);
                setHalaman(0);
                setDataPeralatan([]);
            }
            );

        fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataKaryawan(data.data);
                }
                else {
                    setDataKaryawan([]);
                }
            })
            .catch(error => {
                setDataKaryawan([]);
            }
            );
    }, [props.aplikasi, jumlah_baris]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_produk = (id_jenis) => {
        fetch(props.aplikasi + '/peralatan/tampil_peralatan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPeralatan(data.data);
                    setSumberPeralatan2(data.data);
                    setJumlahHalaman(Math.ceil(data.data.length / jumlah_baris));
                    setHalaman(1);
                    let awal = 1;
                    let akhir = 0;
                    if (data.data.length > 10) {
                        akhir = 10;
                    }
                    else {
                        akhir = data.data.length;
                    }
                    let tampung = [];
                    while (awal <= akhir) {
                        tampung.push(data.data[awal - 1]);
                        awal++;
                    }

                    setDataPeralatan(tampung);
                }
                else {
                    setSumberPeralatan([]);
                    setSumberPeralatan2([]);
                    setJumlahHalaman(0);
                    setHalaman(0);
                    setDataPeralatan([]);
                }
            })
            .catch(error => {
                setSumberPeralatan([]);
                setSumberPeralatan2([]);
                setJumlahHalaman(0);
                setHalaman(0);
                setDataPeralatan([]);
            }
            );
    }

    const bersih_input_habis = () => {
        setTanggalKejadian(null);
        setKaryawan(null);
        setKeterangan("");
        setDataInputPeralatan([]);
        setTotal(0);
    }

    const tambah_pesanan = (id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const kurang_pesanan = (id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(parseFloat(tampung[awal - 1]["subtotal"]));
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const ubah_jumlah_pesanan = (event, id_alat) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_pesanan = (event, id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 1;
                }
                tampung[awal - 1]["subtotal"] = tampung[awal - 1]["jumlah"] * tampung[awal - 1]["harga_beli"];
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const ubah_harga = (event, id_alat) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                tampung[awal - 1]["harga_beli"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_harga = (event, id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                if (parseFloat(event.target.value) >= 0) {
                    tampung[awal - 1]["harga_beli"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["harga_beli"] = 0;
                }
                tampung[awal - 1]["subtotal"] = tampung[awal - 1]["jumlah"] * tampung[awal - 1]["harga_beli"];
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const hapus_pesanan = (id_alat) => {
        var tampung = [...data_input_peralatan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_alat"] === id_alat) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung[awal - 1]["no"] = awal;
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPeralatan(tampung);
        setTotal(tampung_total);
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_peralatan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_peralatan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_peralatan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_peralatan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setSumberPeralatan2(tampung);
        setJumlahHalaman(Math.ceil(tampung.length / jumlah_baris));
        setHalaman(1);
        awal = 1;
        akhir = 0;
        if (tampung.length > 10) {
            akhir = 10;
        }
        else {
            akhir = tampung.length;
        }
        let tampung2 = [];
        while (awal <= akhir) {
            tampung2.push(tampung[awal - 1]);
            awal++;
        }

        setDataPeralatan(tampung2);
    }

    const pindah_halaman = (halaman_baru) => {
        setHalaman(halaman_baru);
        let awal = (halaman_baru * jumlah_baris) - jumlah_baris + 1;
        let akhir = halaman_baru * jumlah_baris;
        let tampung = [];
        while (awal <= akhir) {
            if (awal <= sumber_peralatan2.length) {
                tampung.push(sumber_peralatan2[awal - 1]);
            }
            awal++;
        }

        setDataPeralatan(tampung);
    }

    const tampil_riwayat = (event, varid_alat, varnama_alat) => {
        setNamaAlat(varnama_alat);

        fetch(props.aplikasi + '/peralatan/tampil_riwayat_penyusutan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_alat: varid_alat
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataRiwayat(data.data);
                    setTampilDialogRiwayat(true);
                }
                else {
                    setDataRiwayat([]);
                }
            })
            .catch(error => {
                setDataRiwayat([]);
            }
            );
    }

    const simpan = () => {
        if (tanggal_kejadian === null) {
            setJenisNotif("warning");
            setIsiNotif("Tanggal kejadian wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (karyawan === null) {
            setJenisNotif("warning");
            setIsiNotif("Karyawan wajib diisi");
            setTampilNotif(true);
            document.getElementById("karyawan").focus();
            return;
        }

        if (keterangan.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Keterangan wajib diisi");
            setTampilNotif(true);
            document.getElementById("keterangan").focus();
            return;
        }

        if (data_input_peralatan.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Peralatan belum dipilih");
            setTampilNotif(true);
            return;
        }

        var tahun = String(tanggal_kejadian.getFullYear());
        var bulan = String(tanggal_kejadian.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(tanggal_kejadian.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_tanggal_kejadian = tahun + "-" + bulan + "-" + tanggal;

        setProses(true);
        fetch(props.aplikasi + '/akuntansi/simpan_kerugian.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        tanggal: format_tanggal_kejadian,
                        id_karyawan: karyawan.id_karyawan,
                        nama_karyawan: karyawan.nama,
                        keterangan: keterangan,
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        data_belanja: data_input_peralatan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_habis();
                    tampil_produk();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Box>
                    <Grid container spacing={1} sx={{ padding: "10px" }}>
                        <Grid item xs={12} sm={8}>
                            <Grid item xs={12}>
                                <Card
                                    sx={{ padding: "10px" }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Tgl. Kejadian
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        id="tanggal_kejadian"
                                                        inputFormat="dd-MM-yyyy"
                                                        value={tanggal_kejadian}
                                                        onChange={(newValue) => {
                                                            setTanggalKejadian(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Karyawan
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <Autocomplete
                                                    id="karyawan"
                                                    options={data_karyawan}
                                                    getOptionLabel={(option) => option.nama}
                                                    inputValue={input_karyawan}
                                                    value={karyawan}
                                                    onInputChange={(e, nilai) => setInputKaryawan(nilai)}
                                                    onChange={(e, nilai) => setKaryawan(nilai)}
                                                    renderInput={(params) => <TextField {...params} key={params.id_karyawan} variant="outlined" size="small" />}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Keterangan
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <TextField
                                                    id="keterangan"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={keterangan}
                                                    onChange={(e) => setKeterangan(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />

                                    <Typography variant="h6">
                                        Daftar Kerugian
                                    </Typography>
                                    <Divider />

                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center"><Typography variant="caption">Nama</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Nilai Kerugian</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Jumlah</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Satuan</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Subtotal</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Aksi</Typography></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_input_peralatan.map((data_input_peralatan) => {
                                                    return (
                                                        <StyledTableRow key={data_input_peralatan.id_alat}>
                                                            <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_input_peralatan.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "right", width: "100px" }
                                                                    }}

                                                                    value={data_input_peralatan.harga_beli}
                                                                    onChange={(e) => ubah_harga(e, data_input_peralatan.id_alat)}
                                                                    onBlur={(e) => perbaiki_harga(e, data_input_peralatan.id_alat)}
                                                                />
                                                            </TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_pesanan(data_input_peralatan.id_alat)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "center", width: "50px" }
                                                                    }}
                                                                    value={data_input_peralatan.jumlah}
                                                                    onChange={(e) => ubah_jumlah_pesanan(e, data_input_peralatan.id_alat)}
                                                                    onBlur={(e) => perbaiki_pesanan(e, data_input_peralatan.id_alat)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_pesanan(data_input_peralatan.id_alat)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_input_peralatan.satuan}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_input_peralatan.subtotal2}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_pesanan(data_input_peralatan.id_alat)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell align="right" colSpan={4}>
                                                        <Typography variant="h6">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br />

                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveOutlined />}
                                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                        loading={proses}
                                        onClick={() => simpan()}
                                    >
                                        Simpan
                                    </LoadingButton>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid
                                    item xs={12}
                                    key={data_peralatan.id_alat}
                                >
                                    <Card
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Cari berdasarkan nama alat"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchOutlined />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={cari}
                                        />
                                    </Card>
                                </Grid>

                                <Grid
                                    item xs={12}
                                    key={data_peralatan.id_alat}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>

                                {data_peralatan.map((data_peralatan) => {
                                    return (
                                        <Grid
                                            item xs={12}
                                            key={data_peralatan.id_alat}
                                            onClick={(event) => {
                                                if (event.target.id !== "riwayat") {
                                                    var tampung = [];
                                                    var awal, akhir = 0;
                                                    if (data_input_peralatan.length > 0) {
                                                        tampung = data_input_peralatan;
                                                        awal = 1;
                                                        akhir = tampung.length;
                                                        var cari = "";
                                                        while (awal <= akhir) {
                                                            if (tampung[awal - 1]["id_alat"] === data_peralatan.id_alat) {
                                                                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                                                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                                                                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                                cari = "ada";
                                                                break;
                                                            }
                                                            awal++;
                                                        }

                                                        if (cari === "") {
                                                            tampung.push({ "id_alat": data_peralatan.id_alat, "nama": data_peralatan.nama, "harga_beli": data_peralatan.harga_beli, "jumlah": 1, "satuan": data_peralatan.satuan, "subtotal": data_peralatan.harga_beli, "subtotal2": data_peralatan.harga_beli2 });
                                                        }
                                                    }
                                                    else {
                                                        tampung.push({ "id_alat": data_peralatan.id_alat, "nama": data_peralatan.nama, "harga_beli": data_peralatan.harga_beli, "jumlah": 1, "satuan": data_peralatan.satuan, "subtotal": data_peralatan.harga_beli, "subtotal2": data_peralatan.harga_beli2 });
                                                    }

                                                    var total = 0;
                                                    awal = 1;
                                                    akhir = tampung.length;
                                                    while (awal <= akhir) {
                                                        total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                        awal++;
                                                    }

                                                    setDataInputPeralatan(tampung);
                                                    setTotal(total);
                                                }
                                            }}
                                        >
                                            <Card
                                                sx={
                                                    parseFloat(data_peralatan.jumlah) <= parseFloat(data_peralatan.stok_minimal)
                                                        ? {
                                                            backgroundColor: "#f99f87"
                                                        }
                                                        : {}
                                                }
                                            >
                                                <List>
                                                    <ListItem button>
                                                        <ListItemText
                                                            primary={
                                                                <Grid container>
                                                                    <Grid
                                                                        container
                                                                        item xs={10}
                                                                    >
                                                                        <Typography variant="body1" sx={{ display: "inline" }}>{data_peralatan.nama}</Typography>
                                                                    </Grid>

                                                                    <Grid
                                                                        container justifyContent="flex-end"
                                                                        item xs={2}
                                                                        sx={parseInt(data_peralatan.penyusutan) > 0 ? {} : { display: "none" }}
                                                                    >
                                                                        <Tooltip title="Riwayat Penyusutan">
                                                                            <IconButton
                                                                                edge="end"
                                                                                size="small"
                                                                                sx={{
                                                                                    padding: "0"
                                                                                }}
                                                                            >
                                                                                <HistoryIcon
                                                                                    id="riwayat"
                                                                                    sx={{ color: "#219cd9" }}
                                                                                    onClick={(event) => tampil_riwayat(event, data_peralatan.id_alat, data_peralatan.nama)}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            }>

                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Card>
                                        </Grid>
                                    );
                                })}

                                <Grid
                                    item xs={12}
                                    key={data_peralatan.id_alat}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={tampil_dialog_riwayat}
                onClose={() => setTampilDialogRiwayat(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Riwayat Penyusutan
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">Nama Alat : {nama_alat}</Typography>

                    <TableContainer component={Paper}>
                        <Table stickyHeader size="small" aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center"><Typography variant="caption">Keterangan</Typography></StyledTableCell>
                                    <StyledTableCell align="center"><Typography variant="caption">Tgl. Penyusutan</Typography></StyledTableCell>
                                    <StyledTableCell align="center"><Typography variant="caption">Jumlah</Typography></StyledTableCell>
                                    <StyledTableCell align="center"><Typography variant="caption">Satuan</Typography></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data_riwayat.map((data_riwayat, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_riwayat.keterangan}</Typography></TableCell>
                                            <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_riwayat.tgl_transaksi}</Typography></TableCell>
                                            <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_riwayat.jumlah}</Typography></TableCell>
                                            <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_riwayat.satuan}</Typography></TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogRiwayat(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Kerugian);
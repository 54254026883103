import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

//gambar
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import PrintIcon from '@mui/icons-material/Print';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Pesanan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_pesanan, setDataPesanan] = useState([]);
    const [id_pesanan, setIdPesanan] = useState("");
    const [status_simpan, setStatusSimpan] = useState("Simpan");

    const [posisi_pesan, setPosisiPesan] = useState("data pemesan");
    const [nama, setNama] = useState("");
    const [metode, setMetode] = useState("D");
    const [data_meja, setDataMeja] = useState([]);
    const [no_meja, setNoMeja] = useState("");
    const [no_meja_lama, setNoMejaLama] = useState("");
    const [booking, setBooking] = useState("0");
    const [tampil_dialog_booking, setTampilDialogBooking] = useState(false);
    const [data_jenis, setDataJenis] = useState([]);
    const [jenis, setJenis] = useState("");
    // const [sumber_produk, setSumberProduk] = useState([]);
    const [data_produk, setDataProduk] = useState([]);
    const [data_pelayan, setDataPelayan] = useState([]);
    const [pelayan, setPelayan] = useState("");
    const [dp, setDp] = useState(0);
    const [metode_dp, setMetodeDp] = useState("M1");
    const [tampil_dialog_simpan, setTampilDialogSimpan] = useState(false);

    const [data_input_pesanan, setDataInputPesanan] = useState([]);
    const [total, setTotal] = useState(0);
    const [data_jumlah_tambahan, setDataJumlahTambahan] = useState([]);
    const [no_tambahan, setNoTambahan] = useState("");

    const [pencarian, setPencarian] = useState("");
    const [jumlah_baris] = useState(20);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(1);

    const [tampil_dialog_pesanan, setTampilDialogPesanan] = useState(false);
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [tampil_dialog_tambah_menu, setTampilDialogTambahMenu] = useState(false);
    const [tampil_dialog_jumlah_tambahan, setTampilDialogJumlahTambahan] = useState(false);
    const [tampil_dialog_pembatalan, setTampilDialogPembatalan] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pesanan/tampil_pesanan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                }
                else {
                    setDataPesanan([]);
                }
            })
            .catch(error => {
                setDataPesanan([]);
            }
            );

        fetch(props.aplikasi + '/pengguna/tampil_pelayan.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPelayan(data.data);
                }
                else {
                    setDataPelayan([]);
                }
            })
            .catch(error => {
                setDataPelayan([]);
            }
            );

        setInterval(() => {
            fetch(props.aplikasi + '/pesanan/tampil_pesanan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataPesanan(data.data);
                    }
                    else {
                        setDataPesanan([]);
                    }
                })
                .catch(error => {
                    setDataPesanan([]);
                }
                );
        }, 10000)
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pesanan = () => {
        setProses(true);
        fetch(props.aplikasi + '/pesanan/tampil_pesanan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPesanan(data.data);
                    setProses(false);
                }
                else {
                    setDataPesanan([]);
                    setProses(false);
                }
            })
            .catch(error => {
                setDataPesanan([]);
                setProses(false);
            }
            );
    }

    const tampil_tambahan = () => {
        setProses(true);
        fetch(props.aplikasi + '/pesanan/tampil_jumlah_tambahan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataJumlahTambahan(data.data);
                    setTampilDialogJumlahTambahan(true);
                    setProses(false);
                }
                else {
                    setDataJumlahTambahan([]);
                    setJenisNotif("warning");
                    setIsiNotif("Tidak ada tambahan untuk pesanan ini");
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setDataJumlahTambahan([]);
                setProses(false);
            }
            );
    }

    const switch_warna_metode = (param) => {
        switch (param) {
            case "D":
                return "#e3855f";
            case "T":
                return "#de88e0";
            default:
                break;
        }
    }

    const switch_warna_booking = (param) => {
        switch (param) {
            case "1":
                return "red";
            case "0":
                return "#37ce9b";
            default:
                break;
        }
    }

    const switch_metode = (param) => {
        switch (param) {
            case "D":
                return "Dine-In";
            case "T":
                return "Take Away";
            default:
                break;
        }
    }

    const bersih_input_pesanan = () => {
        setIdPesanan("");
        setNama("");
        setMetode("D");
        setNoMeja("");
        setNoMejaLama("");
        setDataJenis([]);
        setJenis("");
        // setSumberProduk([]);
        setPelayan("");
        setDataProduk([]);
        setDataInputPesanan([]);
        setPosisiPesan("data pemesan");
        setTotal(0);
        setPencarian("");
        setJumlahHalaman(0);
        setHalaman(1);
    }

    const tampil_meja = () => {
        setProses(true);
        fetch(props.aplikasi + '/meja/tampil_meja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMeja(data.data);
                    setProses(false);
                }
                else {
                    setDataMeja([]);
                    setProses(false);
                }
            })
            .catch(error => {
                setDataMeja([]);
                setProses(false);
            }
            );
    }

    const tampil_jenis = () => {
        return new Promise((resolve) => {
            fetch(props.aplikasi + '/jenis_produk/tampil_jenis_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            status: "1"
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        resolve(data.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(error => {
                    resolve([]);
                }
                );
        });
    }

    const tampil_produk = (id_jenis, halaman_baru) => {
        return new Promise((resolve) => {
            fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            status: "1",
                            jenis: id_jenis,
                            cari: pencarian,
                            jumlah_baris: jumlah_baris,
                            halaman: halaman_baru
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        if (id_jenis !== "" && id_jenis !== null) {
                            setJumlahHalaman(Math.ceil(data.jumlah_data / jumlah_baris));
                            resolve(data.data);
                            return;
                        }
                        resolve(data.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(error => {
                    resolve([]);
                }
                );
        });
    }

    const cari = async () => {
        setProses(true);
        setHalaman(1);
        const proses_produk = await tampil_produk(jenis, 1);
        // setSumberProduk(proses_produk);
        setDataProduk(proses_produk);
        setProses(false);
    }

    const pindah_halaman = async (halaman_baru) => {
        setProses(true);
        setHalaman(halaman_baru);
        const proses_produk = await tampil_produk(jenis, halaman_baru);
        // setSumberProduk(proses_produk);
        setDataProduk(proses_produk);
        setProses(false);
    }

    const ubah_jenis_produk = async (id_jenis) => {
        setProses(true);
        setJenis(id_jenis);
        setHalaman(1);
        const proses_produk = await tampil_produk(id_jenis, 1);
        // setSumberProduk(proses_produk);
        setDataProduk(proses_produk);
        setProses(false);
    }

    const tambah_pesanan = (id_produk) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["jumlah"] = parseInt(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["subtotal"] = parseInt(tampung[awal - 1]["jumlah"]) * (parseInt(tampung[awal - 1]["harga"]- tampung[awal - 1]["diskon_nominal"]));
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const kurang_pesanan = (id_produk) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseInt(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseInt(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["subtotal"] = parseInt(tampung[awal - 1]["jumlah"]) * (parseInt(tampung[awal - 1]["harga"]- tampung[awal - 1]["diskon_nominal"]));
                tampung[awal - 1]["subtotal2"] = format_rupiah(parseInt(tampung[awal - 1]["subtotal"]));
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const ubah_jumlah_pesanan = (event, id_produk) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseInt(tampung[awal - 1]["jumlah"]) * (parseInt(tampung[awal - 1]["harga"]- tampung[awal - 1]["diskon_nominal"]));
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_pesanan = (event, id_produk) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 1;
                }
                tampung[awal - 1]["subtotal"] = parseInt(tampung[awal - 1]["jumlah"]) * (parseInt(tampung[awal - 1]["harga"]- tampung[awal - 1]["diskon_nominal"]));
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const ubah_catatan = (event, id_produk) => {
        var tampung = [...data_input_pesanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                tampung[awal - 1]["catatan"] = event.target.value;
                break;
            }
            awal++;
        }

        setDataInputPesanan(tampung);
    }

    const hapus_pesanan = (id_produk) => {
        var tampung = data_input_pesanan;
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung[awal - 1]["no"] = awal;
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputPesanan(tampung);
        setTotal(tampung_total);
    }

    const tampil_pesanan_detail = (id_pesanan) => {
        setProses(true);
        fetch(props.aplikasi + '/pesanan/tampil_pesanan_detail.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataInputPesanan(data.data);

                    var tampung = data.data;
                    var tampung_total = 0;
                    var awal = 1;
                    var akhir = tampung.length;
                    while (awal <= akhir) {
                        tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
                        awal++;
                    }

                    setTotal(tampung_total);
                    setProses(false);
                }
                else {
                    setDataInputPesanan([]);
                    setTotal(0);
                    setProses(false);
                }
            })
            .catch(error => {
                setDataInputPesanan([]);
                setTotal(0);
                setProses(false);
            }
            );
    }

    const ubah_dp = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setDp(event.target.value);
    }

    const perbaiki_dp = (event) => {
        if (event.target.value.trim() === "") {
            setDp(0);
        }
    }

    const simpan = () => {
        setProses(true);
        if (status_simpan === "Simpan") {
            fetch(props.aplikasi + '/pesanan/simpan_pesanan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_pesanan: id_pesanan,
                            nama: nama,
                            metode: metode,
                            no_meja: no_meja,
                            no_meja_lama: no_meja_lama,
                            data_pesanan: data_input_pesanan,
                            bayar_booking: dp,
                            id_pengguna: pelayan
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_pesanan();
                        tampil_pesanan();
                        setTampilDialog(false);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        setProses(false);
                    }
                    else {
                        tampil_pesanan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pesanan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/pesanan/simpan_pesanan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_pesanan: id_pesanan,
                            nama: nama,
                            metode: metode,
                            no_meja: no_meja,
                            no_meja_lama: no_meja_lama,
                            data_pesanan: data_input_pesanan,
                            bayar_booking: dp,
                            id_pengguna: pelayan
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_pesanan();
                        tampil_pesanan();
                        setTampilDialog(false);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        setProses(false);
                    }
                    else {
                        tampil_pesanan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pesanan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    setProses(false);
                }
                );
        }
    }

    const simpan_tambah = () => {
        if (data_input_pesanan.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Produk belum dipilih");
            setTampilNotif(true);
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/pesanan/simpan_pesanan_tambah.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan,
                        data_pesanan: data_input_pesanan,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    tampil_pesanan();
                    setTampilDialogTambahMenu(false);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    tampil_pesanan();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_pesanan();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/pesanan/hapus_pesanan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_pesanan();
                    tampil_pesanan();
                    setTampilDialog(false);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    tampil_pesanan();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_pesanan();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_pembatalan = () => {
        setProses(true);
        fetch(props.aplikasi + '/pesanan/simpan_pembatalan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pesanan: id_pesanan,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 75,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        if (id_pesanan !== "") {
                            bersih_input_pesanan();
                        }
                        setStatusSimpan("Simpan");
                        setTampilDialog(true);
                    }}
                >
                    <AddIcon />
                </Fab>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pesanan.map((data_pesanan) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_pesanan.id_pesanan}
                                onClick={() => {
                                    tampil_pesanan_detail(data_pesanan.id_pesanan);
                                    setIdPesanan(data_pesanan.id_pesanan);
                                    setNama(data_pesanan.nama_booking);
                                    setMetode(data_pesanan.metode);
                                    setNoMeja(data_pesanan.no_meja);
                                    setNoMejaLama(data_pesanan.no_meja);
                                    setPelayan(data_pesanan.id_pengguna);
                                    setStatusSimpan("Perbarui");
                                    setPosisiPesan("data pemesan");
                                    setTampilDialogPesanan(true);
                                }}
                            >
                                <Card sx={
                                    data_pesanan.status === "P"
                                        ?
                                        { backgroundColor: "#66615b", color: "white" }
                                        :
                                        { backgroundColor: "#37ce9b", color: "white" }
                                }>
                                    <List>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: switch_warna_metode(data_pesanan.metode)
                                                    }}
                                                >
                                                    {data_pesanan.no_meja !== "" ? data_pesanan.no_meja : null}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{data_pesanan.nama_booking}</Typography>}
                                                secondary={
                                                    <Typography noWrap variant="body2">{switch_metode(data_pesanan.metode)}</Typography>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog_booking}
                onClose={() => setTampilDialogBooking(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Booking
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Meja ini sedang digunakan / booking. Anda yakin ingin menambahkan tagihan baru?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogBooking(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={async () => {
                            setTampilDialogBooking(false);
                            const proses_jenis = await tampil_jenis();
                            const proses_produk = await tampil_produk(proses_jenis[0].id_jenis, 1);
                            setDataJenis(proses_jenis);
                            setJenis(proses_jenis[0].id_jenis);
                            // setSumberProduk(proses_produk);
                            setDataProduk(proses_produk);
                            setPosisiPesan("menu");
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                open={tampil_dialog_simpan}
                onClose={() => setTampilDialogSimpan(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Simpan
                </DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container item spacing={1} xs={12}>
                        <Grid container item spacing={1} xs={12}>
                            <Grid item xs={5}>
                                <TextField
                                    id="harga"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label="Jumlah DP"
                                    disabled={id_pesanan !== "" ? true : false}
                                    value={dp}
                                    onChange={(event) => ubah_dp(event)}
                                    onBlur={(e) => perbaiki_dp(e)}
                                />
                            </Grid>

                            <Grid item xs={7}>
                                <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                    Rp. {format_rupiah(parseInt(dp))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogSimpan(false);
                            simpan();
                        }}
                    >
                        Simpan
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogSimpan(false)}
                        autoFocus
                    >
                        Tutup
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembatalan}
                onClose={() => setTampilDialogPembatalan(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Pembatalan Pesanan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan pesanan ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogPembatalan(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            simpan_pembatalan();
                            setTampilDialogPembatalan(false);
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="sm"
                open={tampil_dialog_pesanan}
                onClose={() => setTampilDialogPesanan(false)}
            >
                <DialogTitle>Pesanan</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        {nama}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={3}>
                                    <Typography variant="body2">
                                        Metode
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        {switch_metode(metode)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={3}>
                                    <Typography variant="body2">
                                        No. Meja
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        {no_meja}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                onClick={async () => {
                                    const proses_jenis = await tampil_jenis();
                                    const proses_produk = await tampil_produk(proses_jenis[0].id_jenis, 1);
                                    setDataJenis(proses_jenis);
                                    setJenis(proses_jenis[0].id_jenis);
                                    // setSumberProduk(proses_produk);
                                    setDataProduk(proses_produk);
                                    setTotal(0);
                                    setDataInputPesanan([]);
                                    setTampilDialogTambahMenu(true);
                                }}
                            >
                                Tambah
                            </LoadingButton>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "tomato" }}
                                onClick={() => {
                                    fetch(props.aplikasi + '/pesanan/cek_pesanan.php',
                                        {
                                            method: 'POST',
                                            body: JSON.stringify
                                                ({
                                                    id_pesanan: id_pesanan
                                                }),
                                            headers:
                                            {
                                                "Content-type": "application/json; charset=UTF-8"
                                            }
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.kode === 1) {
                                                if (data.data !== "P") {
                                                    setJenisNotif("warning");
                                                    setIsiNotif("Anda tidak bisa mengubah pesanan karena sudah diproses dapur. Pilih Tambah Menu");
                                                    setTampilNotif(true);
                                                    return;
                                                }

                                                setTampilDialog(true);
                                                setTampilDialogPesanan(false);
                                            }
                                            else {
                                                setJenisNotif("warning");
                                                setIsiNotif(data.pesan);
                                                setTampilNotif(true);
                                            }
                                        })
                                        .catch(error => {
                                            setJenisNotif("error");
                                            setIsiNotif("Terjadi kesalahan");
                                            setTampilNotif(true);
                                        }
                                        );
                                }}
                            >
                                Ubah
                            </LoadingButton>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<PrintIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                onClick={() =>
                                    window.open(props.aplikasi + '/pesanan/cetak_pesanan.php?id_pesanan=' + id_pesanan)
                                }
                            >
                                Pesanan
                            </LoadingButton>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<PrintIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                onClick={() =>
                                    // window.open(props.aplikasi + '/pesanan/cetak_pesanan_tambahan.php?id_pesanan=' + id_pesanan)
                                    // setTampilDialogJumlahTambahan(true)
                                    tampil_tambahan()
                                }
                            >
                                Tambahan
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                onClick={() => setTampilDialogHapus(true)}
                            >
                                Hapus
                            </LoadingButton>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                onClick={() => {
                                    setTampilDialogPembatalan(true);
                                }}
                            >
                                Ajukan Pembatalan
                            </LoadingButton>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                startIcon={<CloseIcon />}
                                loadingPosition="start"
                                loading={proses}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                                onClick={() => setTampilDialogPesanan(false)}
                            >
                                Tutup
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_tambah_menu}
                fullScreen
            >
                <Box
                    sx={{ height: "calc(100% - 90px)" }}
                >
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialogTambahMenu(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Tambah Menu
                            </Typography>
                            <LoadingButton
                                loadingPosition="start"
                                loading={proses}
                                autoFocus
                                color="inherit"
                                onClick={() => simpan_tambah()}
                            >
                                Simpan
                            </LoadingButton>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: "64px" }}>
                        <Tabs
                            centered
                            value={jenis}
                            onChange={(event, value) => ubah_jenis_produk(value)}
                        >
                            {data_jenis.map((data_jenis) => {
                                return (
                                    <Tab key={data_jenis.id_jenis} value={data_jenis.id_jenis} label={data_jenis.nama} />
                                )
                            })}
                        </Tabs>
                    </Box>

                    <Box>
                        <Grid container spacing={1} sx={{ padding: "10px" }}>
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Card sx={{ padding: "10px" }}>
                                            <Typography variant="h6">
                                                Data Pemesan
                                            </Typography>
                                            <Divider />
                                            <br />

                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        Nama
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {nama}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        Metode
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {switch_metode(metode)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        No. Meja
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {no_meja}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card sx={{ padding: "10px" }}>
                                            <Typography variant="h6">
                                                Daftar Pesanan
                                            </Typography>
                                            <Divider />

                                            <TableContainer component={Paper}>
                                                <Table stickyHeader size="small" aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">Nama</StyledTableCell>
                                                            <StyledTableCell align="center">Harga</StyledTableCell>
                                                            <StyledTableCell align="center">Diskon</StyledTableCell>
                                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                            <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                            <StyledTableCell align="center">Catatan</StyledTableCell>
                                                            <StyledTableCell align="center">Aksi</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data_input_pesanan.map((data_input_pesanan) => {
                                                            return (
                                                                <StyledTableRow key={data_input_pesanan.id_produk}>
                                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_input_pesanan.nama}</Typography></TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.harga2}</Typography></TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true} sx={{color: "green"}}>{parseFloat(data_input_pesanan.diskon_nominal) !== 0 ? "-" : ""} {format_rupiah(parseFloat(data_input_pesanan.diskon_nominal))}</Typography></TableCell>
                                                                    <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "gray" }}
                                                                            onClick={() => kurang_pesanan(data_input_pesanan.id_produk)}
                                                                        >
                                                                            <RemoveIcon />
                                                                        </Fab>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            inputProps={{
                                                                                style: { textAlign: "center", width: "50px" }
                                                                            }}
                                                                            value={data_input_pesanan.jumlah}
                                                                            onChange={(e) => ubah_jumlah_pesanan(e, data_input_pesanan.id_produk)}
                                                                            onBlur={(e) => perbaiki_pesanan(e, data_input_pesanan.id_produk)}
                                                                        />
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "gray" }}
                                                                            onClick={() => tambah_pesanan(data_input_pesanan.id_produk)}
                                                                        >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.subtotal2}</Typography></TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            value={data_input_pesanan.catatan}
                                                                            onChange={(e) => ubah_catatan(e, data_input_pesanan.id_produk)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "red" }}
                                                                            onClick={() => hapus_pesanan(data_input_pesanan.id_produk)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Fab>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}

                                                        <TableRow>
                                                            <TableCell align="right" colSpan={4}>
                                                                <Typography variant="h6">
                                                                    Total
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <Grid container>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Cari berdasarkan nama produk (tekan Enter)"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        sx={{ margin: "10px" }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchOutlined
                                                        onClick={() => cari()}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={pencarian}
                                        onChange={(event) => setPencarian(event.target.value)}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                cari();
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>

                                <Grid container spacing={1}>
                                    {data_produk.map((data_produk) => {
                                        return (
                                            <Grid item
                                                key={data_produk.id_produk}
                                                sx={{ margin: "10px", width: "150px" }}
                                                onClick={() => {
                                                    if (data_produk.habis === "1") {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Stok produk ini sudah habis");
                                                        setTampilNotif(true);
                                                        return;
                                                    }

                                                    var tampung = [];
                                                    var awal, akhir = 0;
                                                    if (data_input_pesanan.length > 0) {
                                                        tampung = data_input_pesanan;
                                                        awal = 1;
                                                        akhir = tampung.length;
                                                        var cari = "";
                                                        while (awal <= akhir) {
                                                            if (tampung[awal - 1]["id_produk"] === data_produk.id_produk) {
                                                                tampung[awal - 1]["jumlah"] = parseInt(tampung[awal - 1]["jumlah"]) + 1;
                                                                tampung[awal - 1]["subtotal"] = parseInt(tampung[awal - 1]["jumlah"]) * (parseInt(tampung[awal - 1]["harga"]- tampung[awal - 1]["diskon_nominal"]));
                                                                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                                cari = "ada";
                                                                break;
                                                            }
                                                            awal++;
                                                        }

                                                        if (cari === "") {
                                                            tampung.push({ "id_jenis": data_produk.id_jenis, "jenis": data_produk.jenis, "id_produk": data_produk.id_produk, "nama": data_produk.nama, "hpp": data_produk.hpp, "jumlah": 1, "harga": data_produk.harga, "harga2": data_produk.harga2, "diskon_persen": data_produk.diskon_persen, "diskon_nominal": data_produk.diskon_nominal, "subtotal": data_produk.harga - data_produk.diskon_nominal, "subtotal2": format_rupiah(data_produk.harga - data_produk.diskon_nominal), "catatan": "" });
                                                        }
                                                    }
                                                    else {
                                                        tampung.push({ "id_jenis": data_produk.id_jenis, "jenis": data_produk.jenis, "id_produk": data_produk.id_produk, "nama": data_produk.nama, "hpp": data_produk.hpp, "jumlah": 1, "harga": data_produk.harga, "harga2": data_produk.harga2, "diskon_persen": data_produk.diskon_persen, "diskon_nominal": data_produk.diskon_nominal, "subtotal": data_produk.harga - data_produk.diskon_nominal, "subtotal2": format_rupiah(data_produk.harga - data_produk.diskon_nominal), "catatan": "" });
                                                    }

                                                    var total = 0;
                                                    awal = 1;
                                                    akhir = tampung.length;
                                                    while (awal <= akhir) {
                                                        total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                        awal++;
                                                    }

                                                    setDataInputPesanan(tampung);
                                                    setTotal(total);
                                                }}
                                            >
                                                <Card>
                                                    <ImageListItem>
                                                        <CardMedia
                                                            component="img"
                                                            src={props.file + "/Foto/Aplikasi/stok habis.png"}
                                                            sx={data_produk.habis === "1"
                                                                ?
                                                                {
                                                                    position: "absolute",
                                                                    objectFit: "contain",
                                                                    width: "150px",
                                                                    height: "150px"
                                                                }
                                                                :
                                                                {
                                                                    display: "none"
                                                                }
                                                            }
                                                        />

                                                        <CardMedia
                                                            component="img"
                                                            src={props.file + "/Foto/Produk/" + data_produk.foto}
                                                            sx={{
                                                                backgroundColor: "gray",
                                                                width: "150px",
                                                                height: "150px"
                                                            }}
                                                        />
                                                        <ImageListItemBar
                                                            title={data_produk.nama}
                                                            subtitle={"Rp. " + format_rupiah(parseFloat(data_produk.harga - data_produk.diskon_nominal))}
                                                        />
                                                    </ImageListItem>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                                <Grid
                                    item xs={12}
                                    key={data_produk.id_produk}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus pesanan ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={async () => {
                            setTampilDialogHapus(false);
                            setTampilDialogPesanan(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_jumlah_tambahan}
                onClose={() => setTampilDialogJumlahTambahan(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cetak Tambahan Ke
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Pilih Tambahan Yang Akan Dicetak
                    </DialogContentText>

                    <FormControl size="small" fullWidth>
                        <Select
                            id="jumlah_tambahan"
                            variant="outlined"
                            fullWidth
                            value={no_tambahan}
                            onChange={(event) => {
                                setNoTambahan(event.target.value)
                            }}
                        >
                            <MenuItem value="">Pilih No. Tambahan</MenuItem>
                            {data_jumlah_tambahan.map((tampung) => {
                                return (
                                    <MenuItem key={tampung.no_tambahan} value={tampung.no_tambahan}>{tampung.no_tambahan}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loadingPosition="start"
                        loading={proses}
                        startIcon={<PrintIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                        onClick={() => {
                            if (no_tambahan === "") {
                                setJenisNotif("warning");
                                setIsiNotif("No. Tambahan belum dipilih");
                                setTampilNotif(true);
                                return;
                            }
                            window.open(props.aplikasi + '/pesanan/cetak_pesanan_tambahan_nomor.php?id_pesanan=' + id_pesanan + '&no_tambahan=' + no_tambahan)
                        }}
                    >
                        Cetak
                    </LoadingButton>

                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loadingPosition="start"
                        loading={proses}
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogJumlahTambahan(false)}
                    >
                        Tutup
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen
                open={tampil_dialog}
            >
                <Box sx={posisi_pesan === "data pemesan" ? {} : { display: "none" }}>
                    <AppBar
                        sx={{
                            // position: 'relative',
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Data Pemesan
                            </Typography>
                            <LoadingButton autoFocus color="inherit" loadingPosition="start"
                                loading={proses}
                                onClick={async () => {
                                    if (nama.trim() === "") {
                                        setJenisNotif("warning");
                                        setIsiNotif("Nama pemesan wajib diisi");
                                        setTampilNotif(true);
                                        document.getElementById("nama_pemesan").focus();
                                        return;
                                    }

                                    if (metode === "T") {
                                        const proses_jenis = await tampil_jenis();
                                        const proses_produk = await tampil_produk(proses_jenis[0].id_jenis, 1);
                                        setDataJenis(proses_jenis);
                                        setJenis(proses_jenis[0].id_jenis);
                                        // setSumberProduk(proses_produk);
                                        setDataProduk(proses_produk);
                                        setPosisiPesan("menu");
                                    }
                                    else {
                                        tampil_meja();
                                        setPosisiPesan("meja");
                                    }
                                }}
                            >
                                Selanjutnya
                            </LoadingButton>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px" }}>
                        <Grid container item spacing={1} sx={{ padding: "10px" }}>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Nama Pemesan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="nama_pemesan"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={nama}
                                    onChange={(event) => setNama(event.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={1} sx={{ padding: "10px" }}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Pilih Metode
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            value={metode}
                                            onChange={(event) => setMetode(event.target.value)}
                                        >
                                            <MenuItem value="D">Makan Di Tempat (Dine-In)</MenuItem>
                                            <MenuItem value="T">Bungkus (Take Away)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box sx={posisi_pesan === "meja" ? {} : { display: "none" }}>
                    <AppBar
                        sx={{
                            // position: 'relative',
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Pilih Meja
                            </Typography>
                            <LoadingButton
                                loadingPosition="start"
                                loading={proses}
                                autoFocus
                                color="inherit"
                                onClick={() => setPosisiPesan("data pemesan")}
                            >
                                Sebelumnya
                            </LoadingButton>
                            <LoadingButton autoFocus color="inherit" loadingPosition="start"
                                loading={proses}
                                onClick={async () => {
                                    if (no_meja === "") {
                                        setJenisNotif("warning");
                                        setIsiNotif("Anda belum memilih meja");
                                        setTampilNotif(true);
                                        return;
                                    }
                                    if (booking === "1") {
                                        setTampilDialogBooking(true);
                                        return;
                                    }
                                    const proses_jenis = await tampil_jenis();
                                    const proses_produk = await tampil_produk(proses_jenis[0].id_jenis, 1);
                                    setDataJenis(proses_jenis);
                                    setJenis(proses_jenis[0].id_jenis);
                                    // setSumberProduk(proses_produk);
                                    setDataProduk(proses_produk);
                                    setPosisiPesan("menu");
                                }}
                            >
                                Selanjutnya
                            </LoadingButton>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px" }}>
                        <Grid container spacing={1} sx={{ padding: "10px" }}>
                            {data_meja.map((data_meja) => {
                                return (
                                    <Grid
                                        item xs={6} sm={4} md={3} lg={2}
                                        key={data_meja.no_meja}
                                        onClick={async () => {
                                            setNoMeja(data_meja.no_meja);
                                            setBooking(data_meja.booking);
                                            if (data_meja.booking === "1") {
                                                setTampilDialogBooking(true);
                                                return;
                                            }
                                            const proses_jenis = await tampil_jenis();
                                            const proses_produk = await tampil_produk(proses_jenis[0].id_jenis, 1);
                                            setDataJenis(proses_jenis);
                                            setJenis(proses_jenis[0].id_jenis);
                                            // setSumberProduk(proses_produk);
                                            setDataProduk(proses_produk);
                                            setPosisiPesan("menu");
                                        }}
                                    >
                                        <Card
                                            sx={data_meja.no_meja === no_meja ? { backgroundColor: "#37ce9b", color: "white" } : { backgroundColor: "#66615b", color: "white" }}
                                        >
                                            <List>
                                                <ListItem button>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            sx={{
                                                                backgroundColor: switch_warna_booking(data_meja.booking),
                                                            }}
                                                        >
                                                            {data_meja.no_meja}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<Typography noWrap variant="subtitle1">{"Kapasitas : " + data_meja.kapasitas}</Typography>}
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </Box>

                <Box sx={posisi_pesan === "menu" ? { height: "calc(100% - 90px)" } : { display: "none" }}>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Pilih Menu
                            </Typography>
                            <LoadingButton autoFocus color="inherit" loadingPosition="start"
                                loading={proses}
                                onClick={() => {
                                    if (metode === "T") {
                                        setPosisiPesan("data pemesan");
                                    }
                                    else {
                                        setPosisiPesan("meja");
                                    }
                                }}
                            >
                                Sebelumnya
                            </LoadingButton>
                            <LoadingButton autoFocus color="inherit" loadingPosition="start"
                                loading={proses}
                                onClick={() => {
                                    if (pelayan === "") {
                                        setJenisNotif("warning");
                                        setIsiNotif("Pelayan belum dipilih");
                                        setTampilNotif(true);
                                        document.getElementById("pelayan").focus();
                                        return;
                                    }

                                    if (data_input_pesanan.length === 0) {
                                        setJenisNotif("warning");
                                        setIsiNotif("Produk belum dipilih");
                                        setTampilNotif(true);
                                        return;
                                    }

                                    setTampilDialogSimpan(true);
                                }}
                            >
                                Simpan
                            </LoadingButton>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: "64px" }}>
                        <Tabs
                            centered
                            value={jenis}
                            onChange={(event, value) => ubah_jenis_produk(value)}
                        >
                            {data_jenis.map((data_jenis) => {
                                return (
                                    <Tab key={data_jenis.id_jenis} value={data_jenis.id_jenis} label={data_jenis.nama} />
                                )
                            })}
                        </Tabs>
                    </Box>

                    <Box>
                        <Grid container spacing={1} sx={{ padding: "10px" }}>
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Card sx={{ padding: "10px" }}>
                                            <Typography variant="h6">
                                                Data Pemesan
                                            </Typography>
                                            <Divider />
                                            <br />

                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        Nama
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {nama}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        Metode
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {switch_metode(metode)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        No. Meja
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {no_meja}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item>
                                                <Grid item xs={2}>
                                                    <Typography variant="body2">
                                                        Pelayan
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography variant="body2">
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <FormControl size="small" fullWidth>
                                                        <Select
                                                            id="pelayan"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={pelayan}
                                                            onChange={(event) => setPelayan(event.target.value)}
                                                        >
                                                            <MenuItem value="">Pilih Pelayan</MenuItem>
                                                            {data_pelayan.map((tampung) => {
                                                                return (
                                                                    <MenuItem key={tampung.id_pengguna} value={tampung.id_pengguna}>{tampung.nama}</MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card sx={{ padding: "10px" }}>
                                            <Typography variant="h6">
                                                Daftar Pesanan
                                            </Typography>
                                            <Divider />

                                            <TableContainer component={Paper}>
                                                <Table stickyHeader size="small" aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">Nama</StyledTableCell>
                                                            <StyledTableCell align="center">Harga</StyledTableCell>
                                                            <StyledTableCell align="center">Diskon</StyledTableCell>
                                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                            <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                            <StyledTableCell align="center">Catatan</StyledTableCell>
                                                            <StyledTableCell align="center">Aksi</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data_input_pesanan.map((data_input_pesanan) => {
                                                            return (
                                                                <StyledTableRow key={data_input_pesanan.id_produk}>
                                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_input_pesanan.nama}</Typography></TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.harga2}</Typography></TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true} sx={{color: "green"}}>{parseFloat(data_input_pesanan.diskon_nominal) !== 0 ? "-" : ""} {format_rupiah(parseFloat(data_input_pesanan.diskon_nominal))}</Typography></TableCell>
                                                                    <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "gray" }}
                                                                            onClick={() => kurang_pesanan(data_input_pesanan.id_produk)}
                                                                        >
                                                                            <RemoveIcon />
                                                                        </Fab>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            inputProps={{
                                                                                style: { textAlign: "center", width: "50px" }
                                                                            }}
                                                                            value={data_input_pesanan.jumlah}
                                                                            onChange={(e) => ubah_jumlah_pesanan(e, data_input_pesanan.id_produk)}
                                                                            onBlur={(e) => perbaiki_pesanan(e, data_input_pesanan.id_produk)}
                                                                        />
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "gray" }}
                                                                            onClick={() => tambah_pesanan(data_input_pesanan.id_produk)}
                                                                        >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </TableCell>
                                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.subtotal2}</Typography></TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <TextField
                                                                            variant="outlined"
                                                                            size="small"
                                                                            value={data_input_pesanan.catatan}
                                                                            onChange={(e) => ubah_catatan(e, data_input_pesanan.id_produk)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell size="small" align="center">
                                                                        <Fab
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{ backgroundColor: "red" }}
                                                                            onClick={() => hapus_pesanan(data_input_pesanan.id_produk)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Fab>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}

                                                        <TableRow>
                                                            <TableCell align="right" colSpan={4}>
                                                                <Typography variant="h6">
                                                                    Total
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <Grid container>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Cari berdasarkan nama produk (tekan Enter)"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        sx={{ margin: "10px" }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchOutlined
                                                        onClick={() => cari()}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={pencarian}
                                        onChange={(event) => setPencarian(event.target.value)}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                cari();
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>

                                <Grid container spacing={1}>
                                    {data_produk.map((data_produk) => {
                                        return (
                                            <Grid item
                                                key={data_produk.id_produk}
                                                sx={{ margin: "10px", width: "150px" }}
                                                onClick={() => {
                                                    if (data_produk.habis === "1") {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Stok produk ini sudah habis");
                                                        setTampilNotif(true);
                                                        return;
                                                    }

                                                    var tampung = [];
                                                    var awal, akhir = 0;
                                                    if (data_input_pesanan.length > 0) {
                                                        tampung = data_input_pesanan;
                                                        awal = 1;
                                                        akhir = tampung.length;
                                                        var cari = "";
                                                        while (awal <= akhir) {
                                                            if (tampung[awal - 1]["id_produk"] === data_produk.id_produk) {
                                                                tampung[awal - 1]["jumlah"] = parseInt(tampung[awal - 1]["jumlah"]) + 1;
                                                                tampung[awal - 1]["subtotal"] = parseInt(tampung[awal - 1]["jumlah"]) * (parseInt(tampung[awal - 1]["harga"]- tampung[awal - 1]["diskon_nominal"]));
                                                                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                                cari = "ada";
                                                                break;
                                                            }
                                                            awal++;
                                                        }

                                                        if (cari === "") {
                                                            tampung.push({ "id_jenis": data_produk.id_jenis, "jenis": data_produk.jenis, "id_produk": data_produk.id_produk, "nama": data_produk.nama, "hpp": data_produk.hpp, "jumlah": 1, "harga": data_produk.harga, "harga2": data_produk.harga2, "diskon_persen": data_produk.diskon_persen, "diskon_nominal": data_produk.diskon_nominal, "subtotal": data_produk.harga - data_produk.diskon_nominal, "subtotal2": format_rupiah(data_produk.harga - data_produk.diskon_nominal), "catatan": "" });
                                                        }
                                                    }
                                                    else {                                                        
                                                        tampung.push({ "id_jenis": data_produk.id_jenis, "jenis": data_produk.jenis, "id_produk": data_produk.id_produk, "nama": data_produk.nama, "hpp": data_produk.hpp, "jumlah": 1, "harga": data_produk.harga, "harga2": data_produk.harga2, "diskon_persen": data_produk.diskon_persen, "diskon_nominal": data_produk.diskon_nominal, "subtotal": data_produk.harga - data_produk.diskon_nominal, "subtotal2": format_rupiah(data_produk.harga - data_produk.diskon_nominal), "catatan": "" });
                                                    }

                                                    var total = 0;
                                                    awal = 1;
                                                    akhir = tampung.length;
                                                    while (awal <= akhir) {
                                                        total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                        awal++;
                                                    }

                                                    setDataInputPesanan(tampung);
                                                    setTotal(total);
                                                }}
                                            >
                                                <Card>
                                                    <ImageListItem>
                                                        <CardMedia
                                                            component="img"
                                                            src={props.file + "/Foto/Aplikasi/stok habis.png"}
                                                            sx={data_produk.habis === "1"
                                                                ?
                                                                {
                                                                    position: "absolute",
                                                                    objectFit: "contain",
                                                                    width: "150px",
                                                                    height: "150px"
                                                                }
                                                                :
                                                                {
                                                                    display: "none"
                                                                }
                                                            }
                                                        />

                                                        <CardMedia
                                                            component="img"
                                                            src={props.file + "/Foto/Produk/" + data_produk.foto}
                                                            sx={{
                                                                backgroundColor: "gray",
                                                                width: "150px",
                                                                height: "150px"
                                                            }}
                                                        />
                                                        <ImageListItemBar
                                                            title={data_produk.nama}
                                                            subtitle={"Rp. " + format_rupiah(parseFloat(data_produk.harga - data_produk.diskon_nominal))}
                                                        />
                                                    </ImageListItem>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box sx={posisi_pesan === "ringkasan" ? {} : { display: "none" }}>
                    <AppBar
                        sx={{
                            // position: 'relative',
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#fe9400" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Ringkasan
                            </Typography>
                            <LoadingButton autoFocus color="inherit" loadingPosition="start"
                                loading={proses} onClick={() => setPosisiPesan("menu")}>
                                Sebelumnya
                            </LoadingButton>
                            <LoadingButton autoFocus color="inherit" loadingPosition="start"
                                loading={proses}
                                onClick={simpan}
                            >
                                Simpan
                            </LoadingButton>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px", padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} lg={5}>
                                <Card sx={{ padding: "10px" }}>
                                    <Typography variant="h6">
                                        Data Pemesan
                                    </Typography>
                                    <Divider />
                                    <br />

                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                Nama
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography variant="body2">
                                                {nama}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                Metode
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography variant="body2">
                                                {switch_metode(metode)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                No. Meja
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography variant="body2">
                                                {no_meja}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={6} lg={7}>
                                <Card sx={{ padding: "10px" }}>
                                    <Typography variant="h6">
                                        Daftar Pesanan
                                    </Typography>
                                    <Divider />
                                    <br />

                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Nama</StyledTableCell>
                                                    <StyledTableCell align="center">Harga</StyledTableCell>
                                                    <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                    <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                    <StyledTableCell align="center">Aksi</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_input_pesanan.map((data_input_pesanan) => {
                                                    return (
                                                        <StyledTableRow key={data_input_pesanan.id_produk}>
                                                            <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_input_pesanan.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.harga2}</Typography></TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_pesanan(data_input_pesanan.id_produk)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "center", width: "50px" }
                                                                    }}
                                                                    value={data_input_pesanan.jumlah}
                                                                    onChange={(e) => ubah_jumlah_pesanan(e, data_input_pesanan.id_produk)}
                                                                    onBlur={(e) => perbaiki_pesanan(e, data_input_pesanan.id_produk)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_pesanan(data_input_pesanan.id_produk)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{data_input_pesanan.subtotal2}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_pesanan(data_input_pesanan.id_produk)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell align="right" colSpan={3}>
                                                        <Typography variant="h6">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}

export default forwardRef(Pesanan);
import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Belanja(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [sumber_bahan, setSumberBahan] = useState([]);
    const [sumber_bahan2, setSumberBahan2] = useState([]);
    const [data_bahan, setDataBahan] = useState([]);
    const [no_faktur, setNoFaktur] = useState("");
    const [catatan, setCatatan] = useState("");
    const [data_karyawan, setDataKaryawan] = useState([]);
    const [karyawan, setKaryawan] = useState(null);
    const [input_karyawan, setInputKaryawan] = useState("");
    const [data_input_bahan, setDataInputBahan] = useState([]);
    const [total, setTotal] = useState(0);

    const [jumlah_baris] = useState(10);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(0);

    //fungsi
    useEffect(() => {
        setJenisNotif("info");
        setIsiNotif("Transaksi akan menambah saldo persediaan beserta jumlahnya");
        setTampilNotif(true);
        
        fetch(props.aplikasi + '/bahan_baku/tampil_bahan_baku.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberBahan(data.data);
                    setSumberBahan2(data.data);
                    setJumlahHalaman(Math.ceil(data.data.length / jumlah_baris));
                    setHalaman(1);
                    let awal = 1;
                    let akhir = 0;
                    if (data.data.length > 10) {
                        akhir = 10;
                    }
                    else {
                        akhir = data.data.length;
                    }
                    let tampung = [];
                    while (awal <= akhir) {
                        tampung.push(data.data[awal - 1]);
                        awal++;
                    }

                    setDataBahan(tampung);
                }
                else {
                    setSumberBahan([]);
                    setSumberBahan2([]);
                    setJumlahHalaman(0);
                    setHalaman(0);
                    setDataBahan([]);
                }
            })
            .catch(error => {
                setSumberBahan([]);
                setSumberBahan2([]);
                setJumlahHalaman(0);
                setHalaman(0);
                setDataBahan([]);
            }
            );

        fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataKaryawan(data.data);
                }
                else {
                    setDataKaryawan([]);
                }
            })
            .catch(error => {
                setDataKaryawan([]);
            }
            );
    }, [props.aplikasi, jumlah_baris]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_produk = (id_jenis) => {
        fetch(props.aplikasi + '/bahan_baku/tampil_bahan_baku.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberBahan(data.data);
                    setSumberBahan2(data.data);
                    setJumlahHalaman(Math.ceil(data.data.length / jumlah_baris));
                    setHalaman(1);
                    let awal = 1;
                    let akhir = 0;
                    if (data.data.length > 10) {
                        akhir = 10;
                    }
                    else {
                        akhir = data.data.length;
                    }
                    let tampung = [];
                    while (awal <= akhir) {
                        tampung.push(data.data[awal - 1]);
                        awal++;
                    }

                    setDataBahan(tampung);
                }
                else {
                    setSumberBahan([]);
                    setSumberBahan2([]);
                    setJumlahHalaman(0);
                    setHalaman(0);
                    setDataBahan([]);
                }
            })
            .catch(error => {
                setSumberBahan([]);
                setSumberBahan2([]);
                setJumlahHalaman(0);
                setHalaman(0);
                setDataBahan([]);
            }
            );
    }

    const bersih_input_habis = () => {
        setNoFaktur("");
        setCatatan("");
        setKaryawan(null);
        setDataInputBahan([]);
        setTotal(0);
    }

    const tambah_pesanan = (id_bahan) => {
        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const kurang_pesanan = (id_bahan) => {
        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(parseFloat(tampung[awal - 1]["subtotal"]));
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const ubah_jumlah_pesanan = (event, id_bahan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_pesanan = (event, id_bahan) => {
        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 1;
                }
                tampung[awal - 1]["subtotal"] = tampung[awal - 1]["jumlah"] * tampung[awal - 1]["harga_beli"];
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const ubah_harga = (event, id_bahan) => {
        // const re = /^[0-9\b]+$/;
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                tampung[awal - 1]["harga_beli"] = event.target.value;
                tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const perbaiki_harga = (event, id_bahan) => {
        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                if (parseFloat(event.target.value) >= 0) {
                    tampung[awal - 1]["harga_beli"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["harga_beli"] = 0;
                }
                tampung[awal - 1]["subtotal"] = tampung[awal - 1]["jumlah"] * tampung[awal - 1]["harga_beli"];
                tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                break;
            }
            awal++;
        }

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const hapus_pesanan = (id_bahan) => {
        var tampung = [...data_input_bahan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_bahan"] === id_bahan) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        var tampung_total = 0;
        awal = 1;
        akhir = tampung.length;
        while (awal <= akhir) {
            tampung[awal - 1]["no"] = awal;
            tampung_total = tampung_total + parseFloat(tampung[awal - 1]["subtotal"]);
            awal++;
        }

        setDataInputBahan(tampung);
        setTotal(tampung_total);
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_bahan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_bahan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_bahan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_bahan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setSumberBahan2(tampung);
        setJumlahHalaman(Math.ceil(tampung.length / jumlah_baris));
        setHalaman(1);
        awal = 1;
        akhir = 0;
        if (tampung.length > 10) {
            akhir = 10;
        }
        else {
            akhir = tampung.length;
        }
        let tampung2 = [];
        while (awal <= akhir) {
            tampung2.push(tampung[awal - 1]);
            awal++;
        }

        setDataBahan(tampung2);
    }

    const pindah_halaman = (halaman_baru) => {
        setHalaman(halaman_baru);
        let awal = (halaman_baru * jumlah_baris) - jumlah_baris + 1;
        let akhir = halaman_baru * jumlah_baris;
        let tampung = [];
        while (awal <= akhir) {
            if (awal <= sumber_bahan2.length) {
                tampung.push(sumber_bahan2[awal - 1]);
            }
            awal++;
        }

        setDataBahan(tampung);
    }

    const simpan = () => {
        if (no_faktur.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Faktur wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_faktur").focus();
            return;
        }

        if (karyawan === null) {
            setJenisNotif("warning");
            setIsiNotif("Karyawan wajib diisi");
            setTampilNotif(true);
            document.getElementById("karyawan").focus();
            return;
        }

        if (data_input_bahan.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Bahan baku belum dipilih");
            setTampilNotif(true);
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/bahan_baku/simpan_belanja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        no_faktur: no_faktur,
                        catatan: catatan,
                        id_karyawan: karyawan.id_karyawan,                        
                        nama_karyawan: karyawan.nama,
                        data_belanja: data_input_bahan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_habis();
                    tampil_produk();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Box>
                    <Grid container spacing={1} sx={{ padding: "10px" }}>
                        <Grid item xs={12} sm={8}>
                            <Grid item xs={12}>
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container spacing={1}>
                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    No. Faktur
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <TextField
                                                    id="no_faktur"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={no_faktur}
                                                    onChange={(e) => setNoFaktur(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Nama Karyawan Pelaksana
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <Autocomplete
                                                    id="karyawan"
                                                    options={data_karyawan}
                                                    getOptionLabel={(option) => option.nama}
                                                    inputValue={input_karyawan}
                                                    value={karyawan}
                                                    onInputChange={(e, nilai) => setInputKaryawan(nilai)}
                                                    onChange={(e, nilai) => setKaryawan(nilai)}
                                                    renderInput={(params) => <TextField {...params} key={params.id_karyawan} variant="outlined" size="small" />}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Catatan
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <TextField
                                                    id="catatan"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={catatan}
                                                    onChange={(e) => setCatatan(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />

                                    <Typography variant="h6">
                                        Daftar Belanja
                                    </Typography>
                                    <Divider />

                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center"><Typography variant="caption">Nama</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Harga Beli</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Jumlah</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Satuan</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Subtotal</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Aksi</Typography></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_input_bahan.map((data_input_bahan) => {
                                                    return (
                                                        <StyledTableRow key={data_input_bahan.id_bahan}>
                                                            <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_input_bahan.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "right", width: "100px" }
                                                                    }}

                                                                    value={data_input_bahan.harga_beli}
                                                                    onChange={(e) => ubah_harga(e, data_input_bahan.id_bahan)}
                                                                    onBlur={(e) => perbaiki_harga(e, data_input_bahan.id_bahan)}
                                                                />
                                                            </TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_pesanan(data_input_bahan.id_bahan)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "center", width: "50px" }
                                                                    }}
                                                                    value={data_input_bahan.jumlah}
                                                                    onChange={(e) => ubah_jumlah_pesanan(e, data_input_bahan.id_bahan)}
                                                                    onBlur={(e) => perbaiki_pesanan(e, data_input_bahan.id_bahan)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_pesanan(data_input_bahan.id_bahan)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="center"><Typography variant="caption" noWrap={true}>{data_input_bahan.satuan}</Typography></TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="caption" noWrap={true}>{data_input_bahan.subtotal2}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_pesanan(data_input_bahan.id_bahan)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell align="right" colSpan={4}>
                                                        <Typography variant="h6">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(total)}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br />

                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveOutlined />}
                                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                        loading={proses}
                                        onClick={() => simpan()}
                                    >
                                        Simpan
                                    </LoadingButton>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid
                                    item xs={12}
                                    key={data_bahan.id_bahan}
                                >
                                    <Card
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Cari berdasarkan nama bahan"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchOutlined />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={cari}
                                        />
                                    </Card>
                                </Grid>

                                <Grid
                                    item xs={12}
                                    key={data_bahan.id_bahan}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>

                                {data_bahan.map((data_bahan) => {
                                    return (
                                        <Grid
                                            item xs={12}
                                            key={data_bahan.id_bahan}
                                            onClick={() => {
                                                var tampung = [];
                                                var awal, akhir = 0;
                                                if (data_input_bahan.length > 0) {
                                                    tampung = data_input_bahan;
                                                    awal = 1;
                                                    akhir = tampung.length;
                                                    var cari = "";
                                                    while (awal <= akhir) {
                                                        if (tampung[awal - 1]["id_bahan"] === data_bahan.id_bahan) {
                                                            tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                                            tampung[awal - 1]["subtotal"] = parseFloat(tampung[awal - 1]["jumlah"]) * parseFloat(tampung[awal - 1]["harga_beli"]);
                                                            tampung[awal - 1]["subtotal2"] = format_rupiah(tampung[awal - 1]["subtotal"]);
                                                            cari = "ada";
                                                            break;
                                                        }
                                                        awal++;
                                                    }

                                                    if (cari === "") {
                                                        tampung.push({ "id_bahan": data_bahan.id_bahan, "nama": data_bahan.nama, "harga_beli": data_bahan.harga_beli, "jumlah": 1, "satuan": data_bahan.satuan, "subtotal": data_bahan.harga_beli, "subtotal2": data_bahan.harga_beli2 });
                                                    }
                                                }
                                                else {
                                                    tampung.push({ "id_bahan": data_bahan.id_bahan, "nama": data_bahan.nama, "harga_beli": data_bahan.harga_beli, "jumlah": 1, "satuan": data_bahan.satuan, "subtotal": data_bahan.harga_beli, "subtotal2": data_bahan.harga_beli2 });
                                                }

                                                var total = 0;
                                                awal = 1;
                                                akhir = tampung.length;
                                                while (awal <= akhir) {
                                                    total = total + parseFloat(tampung[awal - 1]["subtotal"]);
                                                    awal++;
                                                }

                                                setDataInputBahan(tampung);
                                                setTotal(total);
                                            }}
                                        >
                                            <Card
                                                sx={
                                                    parseFloat(data_bahan.jumlah) <= parseFloat(data_bahan.stok_minimal)
                                                        ? {
                                                            backgroundColor: "#f99f87"
                                                        }
                                                        : {}
                                                }
                                            >
                                                <List>
                                                    <ListItem button>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body1">{data_bahan.nama}</Typography>
                                                            }
                                                        >
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </Card>
                                        </Grid>
                                    );
                                })}

                                <Grid
                                    item xs={12}
                                    key={data_bahan.id_bahan}
                                >
                                    <Stack spacing={2}>
                                        <Pagination
                                            color="primary"
                                            count={jumlah_halaman}
                                            page={halaman}
                                            onChange={(event, nomor) => {
                                                pindah_halaman(nomor);
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}

export default forwardRef(Belanja);
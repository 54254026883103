//komponen
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

//form
import UtamaAdmin from './Admin/utama';
import UtamaPelayan from './Pelayan/utama';
import UtamaKasir from './Kasir/utama';
import UtamaDapur from './Dapur/utama';
import UtamaBar from './Bar/utama';

// const useUnload = fn => {
//     const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

//     useEffect(() => {
//         cb.current = fn;
//     }, [fn]);

//     useEffect(() => {
//         const onUnload = (...args) => cb.current?.(...args);

//         window.addEventListener("beforeunload", onUnload);

//         return () => window.removeEventListener("beforeunload", onUnload);
//     }, []);
// };

function Utama(props, savedChanges) {
    // useUnload(e => {
    //     e.preventDefault();
    //     e.returnValue = '';
    // });

    //state
    const [status_login, setStatusLogin] = useState(true);

    //fungsi    
    const keluar = () => {
        setStatusLogin(false);
        localStorage.clear();
    }

    if (!localStorage.getItem("login")) {
        return <Navigate to="/login" />;
    }
    else {
        if (localStorage.getItem("level") === "A") {
            return (
                <UtamaAdmin
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
        else if (localStorage.getItem("level") === "P") {
            return (
                <UtamaPelayan
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
        else if (localStorage.getItem("level") === "D") {
            return (
                <UtamaDapur
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
        else if (localStorage.getItem("level") === "B") {
            return (
                <UtamaBar
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
        else if (localStorage.getItem("level") === "K") {
            return (
                <UtamaKasir
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
    }
}

export default Utama;